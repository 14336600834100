<template>
    <div>
        <span>通义法睿</span>
    </div>
</template>
<script>
export default {
    setup() {
        
    },
}
</script>
<style>

</style>