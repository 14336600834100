<template>
  <div>     
            <el-card shadow="never" style="width:100%;height:100vh">
                <div>
                    <el-row :gutter="10">
                        <el-col :span="16">
                        <el-row>
                            <el-col :span="8">
                                <el-input v-model="cx.ajmc" placeholder="请输入案件名称"></el-input>
                            </el-col>
                            <el-col :span="8">
                               <el-select v-model="cx.ajtaty" clearable placeholder="请输入案件类型">
                                    <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                            </el-col>
                            <!-- <el-col :span="8">
                                <el-select v-model="cx.ay" clearable placeholder="请输入案由">
                                    <el-option
                                    v-for="item in tableData1"
                                    :key="item.ay"
                                    :label="item.ay"
                                    :value="item.ay">
                                </el-option>
                                </el-select>
                            </el-col> -->
                        </el-row>
                        </el-col>
                        <el-col :span="4">
                            <el-button type="primary" @click="getUAll">确 定</el-button>
                        </el-col>
                        <el-col :span="4">
                            <el-button type="text" @click="dialogFormVisible = true">新建话题</el-button>
                        </el-col>
                    </el-row>
                </div>
                <div>
                    <el-dialog title="收货地址" :visible.sync="dialogFormVisible">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="话题：" prop="wtName">
                        <el-input v-model="ruleForm.wtName"></el-input>
                    </el-form-item>
                    <el-form-item label="标题：" prop="hsName">
                        <el-input v-model="ruleForm.hsName"></el-input>
                    </el-form-item>
                    <el-form-item label="创建人：" prop="cjrName">
                        <el-input v-model="ruleForm.cjrName"></el-input>
                    </el-form-item>
                    <el-form-item label="分类：" prop="flType">
                        <el-select v-model="ruleForm.flType" clearable placeholder="请选择">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button @click="dialogFormVisible = false">取 消</el-button>
                        <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
                    </div>
                    </el-dialog>
                </div>
                <div>
                    <el-dialog title="查看详情" :visible.sync="dialogForm">
                        <div>
                            <el-card shadow="never">
                              <el-form :model="ruleForm1" :rules="rules" ref="ruleForm1" label-width="100px" class="demo-ruleForm">
                                <el-form-item label="案件名称：" prop="ajmc">
                                    <el-input v-model="ruleForm1.ajmc"></el-input>
                                </el-form-item>
                                <el-form-item label="法院：" prop="fy">
                                    <el-input v-model="ruleForm1.fy"></el-input>
                                </el-form-item>
                                <el-form-item label="所属路径：" prop="addres">
                                    <el-input v-model="ruleForm1.addres"></el-input>
                                </el-form-item>
                                <el-form-item label="法院：" prop="fy">
                                    <el-input v-model="ruleForm1.fy"></el-input>
                                </el-form-item>
                                <el-form-item label="案件类型：" prop="ajtaty">
                                    <el-input v-model="ruleForm1.ajtaty"></el-input>
                                </el-form-item>
                                <el-form-item label="审理程序：" prop="slcy">
                                    <el-input v-model="ruleForm1.slcy"></el-input>
                                </el-form-item>
                                <el-form-item label="裁判日期：" prop="cprq">
                                    <el-input v-model="ruleForm1.cprq"></el-input>
                                </el-form-item>
                                <el-form-item label="广告日期：" prop="gkrq">
                                    <el-input v-model="ruleForm1.gkrq"></el-input>
                                </el-form-item>
                                <el-form-item label="当事人" prop="dsr">
                                    <el-input v-model="ruleForm1.dsr"></el-input>
                                </el-form-item>
                                <el-form-item label="案由" prop="ay">
                                    <el-input v-model="ruleForm1.ay"></el-input>
                                </el-form-item>
                                <el-form-item label="法院依据" prop="flyj">
                                    <el-input v-model="ruleForm1.flyj"></el-input>
                                </el-form-item>
                                <el-form-item label="全文" prop="qwtitle">
                                    <el-input v-model="ruleForm1.qwtitle"></el-input>
                                </el-form-item>
                            </el-form>
                            </el-card>
                        </div>
                    </el-dialog>
                </div>
                
                <div>
                    <template>
                <el-table
                    :data="tableData"
                    border
                    style="width: 100%">
                    <el-table-column
                    fixed
                    prop="ah"
                    label="案号"
                    width="150">
                    </el-table-column>
                    <el-table-column
                    prop="ajmc"
                    label="案件名称"
                    width="120">
                    <template slot-scope="scope">
                        {{ ellipsisText(scope.row.ajmc, 10) }}
                    </template>
                    </el-table-column>
                    <el-table-column
                    prop="fy"
                    label="法院"
                    width="120">
                    </el-table-column>
                    <el-table-column
                    prop="addres"
                    label="所属路径"
                    width="120">
                    </el-table-column>
                    <el-table-column
                    prop="ajtaty"
                    label="案件类型"
                    width="120">
                    </el-table-column>
                    <el-table-column
                    prop="slcy"
                    label="审理程序"
                    width="120">
                    </el-table-column>
                    <el-table-column
                    prop="cprq"
                    label="裁判日期"
                    width="120">
                    </el-table-column>
                    <el-table-column
                    prop="gkrq"
                    label="广告日期"
                    width="120">
                    </el-table-column>
                    <el-table-column
                    prop="dsr"
                    label="当事人"
                    width="120">
                    </el-table-column>
                    <el-table-column
                    prop="ay"
                    label="案由"
                    width="120">
                    </el-table-column>
                    <el-table-column
                    prop="flyj"
                    label="法院依据"
                    width="120">
                    <template slot-scope="scope">
                        {{ ellipsisText(scope.row.flyj, 10) }}
                    </template>
                    </el-table-column>
                    <el-table-column
                    prop="qwtitle"
                    label="全文"
                    width="120">
                    <template slot-scope="scope">
                        {{ ellipsisText(scope.row.qwtitle, 10) }}
                    </template>
                    </el-table-column>
                    <el-table-column
                    fixed="right"
                    label="操作"
                    width="100">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="sava(scope.row)">查看</el-button>
                        <!-- <el-button type="text" size="small" @click="dz">点赞</el-button> -->
                    </template>
                    </el-table-column>
                </el-table>
                <div>
                    <el-popover
                            placement="bottom-end"
                            width="160"
                            v-model="visible">
                            <p>这是一段内容这是一段内容确定删除吗？</p>
                            <div style="text-align: right; margin: 0">
                                <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                                <el-button type="primary" size="mini" @click="visible = false">确定</el-button>
                            </div>
                    </el-popover>
                </div>
                </template>
                </div>
                <div style="margin-top:20px">
                    <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="cx.pageInfo.pageNumber"
                    :page-sizes="[10,20,40,60,100]"
                    :page-size="cx.pageInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                    </el-pagination>
                </div>
            </el-card>
            
  </div>
</template>

<script>
export default {
    data(){
        return{
            visible: false,
            ruleForm1:{

            },
            dialogForm:false,
            options:[
                {
                value: '执行案件',
                label: '执行案件'
                }, {
                value: '民事案件',
                label: '民事案件'
                }, {
                value: '非诉保全审查案件',
                label: '非诉保全审查案件'
                }, {
                value: '刑事案件',
                label: '刑事案件'
                },
                {
                value: '强制清算与破产案件',
                label: '强制清算与破产案件'
                },
                {
                value: '司法制裁案件',
                label: '司法制裁案件'
                },
                {
                value: '管辖案件',
                label: '管辖案件'
                },
                {
                value: '其他案件',
                label: '其他案件'
                }
            ],
            ruleForm:{

            },
            dialogFormVisible:false,
            tableData:[],
            tableData1:[],
            cx:{
              ajmc:'',
              ajtaty:'',
              ay:'',
              pageInfo:{
              pageSize:5,
              pageNumber: 1,
            }
        },
        }
    },
    mounted () {
    this.getUAll(); 
    this.getUAll1();  
    },
    methods: {
        dz(){
            this.visible = true
        },
        sava(row){
            this.dialogForm = true
            this.ruleForm1 = this.ruleForm1 = Object.assign({}, row);
        },
    // 点击按钮提交
					btn(){
						var usercom={
							name:this.user,
							txt:this.comm
						}
						this.list.unshift(usercom)
						this.user="";
						this.comm="";
					},
					// 用户名方法事件
					addItem(){
						// 创建用户名
						var item={
							// 用户名字
						name:this.user,
						// 用户评论
						txt:this.comm,
						};
						
						// 在list前面添加 item    unshift在前面添加
						this.list.unshift(item)
						
						// 清空user
						this.user="";
						// 清空评论
						this.comm="";
					},
					//删除点击事件  获取元素
					delItem(item){
						// 查找元素item在list里的下标
						var ind=this.list.findIndex(value=>value.name==item.name);
						//删除
						// splice 功能（从第几个元素，删除几个 添加内容）
						this.list.splice(ind,1);
					},
    handleSizeChange(val) { //分页
                this.cx.pageInfo.pageSize = val
                this.getUAll()
            },
            handleCurrentChange(val) { //分页
                this.cx.pageInfo.pageNumber = val
                this.getUAll()
    },
    myMessage(type, message) { //操作后成功和失败的弹窗
            this.$message({
                message: message,
                type: type
            });
        },
         // 自定义方法来限制文本长度并添加省略号
    ellipsisText(text, maxLength) {
      if (text && text.length > maxLength) {
        return text.slice(0, maxLength) + '...';
      }
      return text;
    },
    getUAll(){
        this.$axios.post("/wenshu/queryByPage",this.cx).then(res => {
                        console.log(res)
                        if (res.data.data) {
                        this.tableData = res.data.data.records
                        this.total = res.data.data.totalRow
                        }
                    }).catch(err => {
                        this.myMessage("error", err)
                    })
      },

    }
}
</script>

<style>
</style>