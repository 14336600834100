<template>
    <div style="width: 100%;height: 100%;background-color: #fff">
        <iframe src="https://www.tianyancha.com/" style="width: 100%;height: 100%;"></iframe>
    </div>
</template>
<script>
export default {
    setup() {
        
    },
}
</script>
<style>

</style>