<template>
    <div id="app">
        <div style="height:100%;">
           <el-container style="height: 100vh;">
            <el-header style="background:rgb(135, 29, 29);">
              <el-row :gutter="24" style=" line-height:65px">
                <el-col :span="10" >
                  <span style="color:rgb(248 249 251);">
                    <span style="font-size:26px;font-weight: bold;margin-right:450px">正己律师小助手 </span>
                  </span>
                </el-col>
                <el-col :span="14">
                  <span style="color:rgb(248 249 251);">
                  <span style="font-size:16px ;" v-if="dd">正己 律师常用工具</span>
                  </span>
                </el-col>
              </el-row>
            </el-header>
          <el-container style="height:200px;">
            <el-aside width="200px" style="background-color: rgb(255 255 255);">
              <el-menu :default-openeds="['1']" router>
                <el-submenu index="1">
                  <template slot="title"><i class="el-icon-message"></i>案件</template>
                  <el-menu-item-group>
                    <el-menu-item v-if="!isMobile" index="/map">
                         录音库
                    </el-menu-item>
                    <el-menu-item v-else index="/map1">
                         录音库
                    </el-menu-item>
                    <el-menu-item index="/anlik">
                         案例库
                    </el-menu-item>
                    <el-menu-item index="/wenshu">
                         文书库
                    </el-menu-item>
                    <!-- <el-menu-item index="/tettt">
                    <span style="color:#202325;" >
                      写作猫
                    </span>
                    </el-menu-item> -->
                    <el-menu-item index="/xiezuomao">
                    <span style="color:#202325;" >
                      文本校对
                    </span>
                    </el-menu-item>
                  </el-menu-item-group>
                </el-submenu>

                <el-submenu index="2">
                  <template slot="title"><i class="el-icon-message"></i>工具</template>
                  <el-menu-item-group>
                    <el-menu-item index="/Queryall">
                         天眼查
                    </el-menu-item>
                    <el-menu-item>
                    <a href="https://tingwu.aliyun.com/home" target="_blank" style="text-decoration: none;"><span style="color:#202325;">通义听悟</span></a>
                    </el-menu-item>
                    <el-menu-item index="/ksTw">
                         快速图文
                    </el-menu-item>
                    <el-menu-item>
                    <a href="https://tongyi.aliyun.com/farui/chat" target="_blank" style="text-decoration: none;"><span style="color:#202325;">法睿</span></a>
                    </el-menu-item>

                    <el-menu-item>
                    <a href="https://kimi.moonshot.cn/?data_source=tracer&utm_campaign=TR_PbzLg2eV&utm_content=&utm_medium=%E5%BE%AE%E8%BD%AFbing&utm_source=bing&utm_term=&msclkid=146a5c303b691cf6bb643a524fa3b0d9" target="_blank" style="text-decoration: none;"><span style="color:#202325;"></span></a>
                    </el-menu-item>

                  </el-menu-item-group>
                </el-submenu>


                <el-submenu index="5">
                  <template slot="title"><i class="el-icon-message"></i>话术库</template>
                  <el-menu-item-group>
                    <el-menu-item index="inadmin">
                         邀约话术
                    </el-menu-item>
                  </el-menu-item-group>
                </el-submenu>


                

                



            </el-menu>
          </el-aside>
            <el-main style="padding: 0px">
              <router-view></router-view>
            </el-main>
          </el-container>
        </el-container> 
        </div>
        
    </div>
</template>
<script>
import AboutView from '../AboutView.vue';
export default {
  components: { AboutView },
    data(){
        return{
          dd: true,
          isMobile: false,
          hasNavigated: false, // 新增标志位，记录是否已进行过跳转
          receivedUserName: '',
          count: 0,
        }
    },
    created() {
    this.checkDevice();
    window.addEventListener('resize', this.handleResize);
      },
      beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
      },
      methods: {
        checkDevice() {
          // 判断是否为移动设备
            const userAgent = navigator.userAgent;
            if (
              /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(userAgent)
            ) {
              this.isMobile = true;
              this.dd = false;
              // 只有未进行过跳转且当前不在手机端主页才跳转至手机端主页
              if (!this.hasNavigated && this.$route.path !== '/map1' && this.$router) {
                this.hasNavigated = true;
                this.$router.replace('/map1');
              }
            } else {
              this.dd = true
              this.hasNavigated = false; // 当设备变为PC时，重置跳转标志位
              if (this.$route.path !== '/map' && this.$router) {
                this.$router.replace('/map');
              }
            }
        },
        handleResize() {
          this.checkDevice(); // 当窗口大小变化时重新检查设备类型
        },
      },


}
</script>
<style>
  /* *{
    width: 100%;
  } */
  
   html, body{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  .app{
    width: 100%;
    height: 100%;
  }
 .el-header, .el-footer {
    background-color: #2874d7;
    color: #333;
    text-align: left;
    line-height: 60px;
  }
  
  .el-aside {
    background-color: #D3DCE6;
    color: #333;
    text-align: center;
    line-height: 200px;
  }
  
  .el-main {
    background-color: #E9EEF3;
    color: #333;
    text-align: center;
    line-height: 160px;
  }
  
  body > .el-container {
    margin-bottom: 40px;
  }
  
  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }
  
  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }
  @media screen and (max-width: 768px) {
  /* 移动端样式调整 */
  .el-header {
    font-size: 24px; /* 调整标题字体大小 */
  }
  .el-aside {
    display: none; /* 或者设置宽度为0，隐藏侧边栏 */
  }
  /* CSS响应式布局 */
  @media (max-width: 768px) { /* 假设此断点为移动端 */
    .mobile-only { display: block; }
  }
  
  @media (min-width: 769px) { /* 假设此断点为PC端 */
    .pc-only { display: none; }
  }

  .el-container {
    display: flex;
    /* height: 100%; */
    flex-direction: column;
  }

  .el-header,
  .el-footer {
    flex-shrink: 0; /* 防止header和footer被压缩 */
    background-color: #f5f7fa;
    padding: 20px;
  }

  .el-aside {
    flex-shrink: 0;
    /* width: 200px; */
    background-color: #e9eef3;
    overflow-y: auto;
  }

  .el-main {
    flex-grow: 1; /* 让main填充剩余空间 */
    overflow-y: auto;
    background-color: #f8f8f8;
    padding: 20px;
  }
  
}
</style>



