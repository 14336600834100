import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import text1 from '../views/text1/text1.vue'
import demo from '../views/text1/demo.vue'
import login from '../views/text1/login.vue'
import FileAll from '../views/text1/FileAll.vue'
import index from '../views/text1/index.vue'
import AiGPT from '../views/text1/AiGPT.vue'
import update from '../views/text1/update.vue'

import admin from '../views/text1/admin.vue'
import anjs from '../views/text1/anjs.vue'
import wssc from '../views/text1/wssc.vue'
import zpsc from '../views/text1/zpsc.vue'
import map from '../views/text1/map.vue'
import ksTw from '../views/text1/ksTw.vue'
import index2 from '../views/text1/index2.vue'
import luyin from '../views/text1/luyin.vue'
import map1 from '../views/text1/map1.vue'
import anlik from '../views/text1/anlik.vue'
import wenshu from '../views/text1/wenshu.vue'
import Queryall from '../views/text1/Queryall.vue'
import inadmin from '../views/text1/inadmin.vue'
import tettt from '../views/text1/tettt.vue'
import xiezuomao from '../views/text1/xiezuomao.vue'
Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/home',
  //   name: 'home',
  //   component: HomeView
  // },

  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/index',
    name: 'index',
    component: index
  },
  {
    path: '/',
    name: 'text1',
    component: text1,
    children:[        //子路由,嵌套路由 （此处偷个懒，免得单独再列一点）
      {
        path: "/inadmin",
        component : inadmin
      },
      {
        path: "/demo",
        component : demo
      },
      {
        path: "/FileAll",
        component: FileAll
      },
      { 
        path : "/AiGPT",
        component : AiGPT
      },
      {
        path : '/update',
        component : update
      },
      {
        path : '/admin',
        component: admin
      },
      {
        path : '/anjs',
        component: anjs
      },
      {
        path : '/wssc',
        component: wssc
      },
      {
        path : '/zpsc',
        component: zpsc
      },
      {
        path : '/map',
        component : map,
      },{
        path : '/ksTw',
        component : ksTw,
      },
      {
        path : '/index2',
        component : index2,
      },
      {
        path : '/luyin',
        component : luyin,
      },
      {
        path : '/map1',
        component : map1,
      },
      {
        path : '/anlik',
        component : anlik,
      },
      {
        path: "/wenshu",
        component : wenshu,
      },
      {
        path: "/Queryall",
        component : Queryall,
      },
      {
        path: "/tettt",
        component : tettt
      },{
        path: "/xiezuomao",
        component : xiezuomao
      }
      ]

  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
