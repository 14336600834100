<template>
   <div>
    <div>
      <el-card>
        <div>
        <el-input
          :disabled="true"
          ref="input"
          type="textarea"
          :rows="10"
          placeholder="录音的内"
          v-model="from.Text"
          @input="handleInput">
        </el-input>
        </div>

        <div style="margin-top:23px">
          <el-input
          :disabled="true"
          ref="input"
          type="textarea"
          :rows="10"
          placeholder="记录以上实时录音记录"
          v-model="from.Text1">
        </el-input>
        </div>

        <div>
          <audio controls :src="audioUrl" ref="player"></audio>
        </div>
        <!-- <el-button @click="copyToClipboard" :disabled="!from.Text">一键复制</el-button> -->
        <el-button type="warning" icon="el-icon-star-off" circle @click="copyToClipboard" :disabled="!from.Text"></el-button>
        <!-- <p>录音时长：{{ formattedRecordingTime }}</p> -->
      </el-card>
    </div>
    <div style="text-align: right; margin: 0">
      <el-button size="mini" type="text" @click="visible = false">取消</el-button>
      <el-button type="primary" size="mini" @click="visible = false">确定</el-button>
    </div>
    <div>
      <el-row :gutter="5">
      <el-col :span="3">
        <div>
        <el-button type="warning" size="small" @click="startRecord" class="el-icon-video-pause" round>开始录音</el-button>
        </div>
        </el-col>
      <el-col :span="3">
        <div>
        <el-button type="danger" size="small" @click="stopRecord" class="el-icon-video-play" round>结束录音</el-button>
        </div>
        </el-col>
      <el-col :span="3">
      <div>
          <el-upload
          class="upload-demo"
          action=""
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          multiple
          :limit="3"
          :on-exceed="handleExceed"
          :file-list="fileList" :http-request="uploadFile">
          <el-button size="small" type="primary" round>点击上传</el-button>
          </el-upload>
      </div>
      </el-col>
      <el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
      </el-row>
      <!-- <el-button size="small">开始上传录音文件</el-button> -->
    </div>

    <div>
      <div></div>
    </div>
    <div>
    <el-dialog title="基本信息" :visible.sync="dialogFormVisible">
          <el-form :model="formName" :rules="formName1" ref="formName">
            <el-form-item label="签约客户：" :label-width="formLabelWidth">
              <el-input v-model="formName.qykh" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="对手名称：" :label-width="formLabelWidth">
              <el-input v-model="formName.dsName" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" :label-width="formLabelWidth">
              <el-input v-model="formName.dsPhone" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="代理方式" :label-width="formLabelWidth">
              <!-- <el-input v-model="formName.dlType" autocomplete="off"></el-input> -->
              <el-select v-model="formName.dlType" placeholder="请选择" style="width:611px;margin-left:41px">
                  <el-option
                    v-for="item in options1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="录音人角色类型">
              <el-select v-model="formName.lyType" placeholder="请选择" style="width:611px">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="备注"> 
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
                v-model="formName.beiZhu">
              </el-input>
            </el-form-item>
            <el-form-item label="录音次数" :label-width="formLabelWidth">
              <el-input-number v-model="formName.lycs" @change="handleChange" :min="0" :max="10" label="描述文字"></el-input-number>
            </el-form-item>
          </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
      </el-dialog>
      
    </div>
   </div>
   
</template>
<script>


import AudioRecorder from 'js-audio-recorder';
import ClipboardJS from 'clipboard';
import RecordRTC from 'recordrtc'
import SockJS from 'sockjs-client';
import { io } from 'socket.io-client'; // 假设你已经引入了socket.io-client
export default {
  
  
    data(){
        return{
        audioUrl: null,
        intervaltimerid: '', // 间隔时间定时器编号
        tiptext: '点击录音', // 提示文字
        visible:false,
        receivedUserName:'', 
        userid:'', 
        options: [
          {
          value: '1',
          label: '谈案录音'
          }, {
            value: '2',
            label: '律师录音'
          }, {
            value: '3',
            label: '其他'
          },
          {
            value : '4',
            label : '案件委托书'
          }
        ],
        options1: [
          {
          value: '1',
          label: '谈判'
          }, {
            value: '2',
            label: '速讼保'
          }, {
            value: '3',
            label: '谈判+速讼保'
          },
          {
            value : '4',
            label : '一般代理'
          },
          {
            value : '5',
            label : '半风险代理'
          },{
            value : '6',
            label : '全风险代理'
          },{
            value : '7',
            label : '保证金'
          },
          {
            value : '8',
            label : '公益咨询'
          },{
            value : '9',
            label : '常法业务'
          },{
            value : '1.0',
            label : '专项服务/文书'
          }
          ,{
            value : '1.1',
            label : '垫资案件'
          }
          ,{
            value : '1.2',
            label : '调查令'
          }
        ],
        formName1: {
         qykh: [{ required: true, message: '请输入签约客户', trigger: 'blur' },],
         dsName: [{ required: true, message: '请输入对手名称', trigger: 'change' }],
         anyou: [{ required: true, message: '请输入案由', trigger: 'change' }],
         dlType: [{ required: true, message: '请输入代理模式', trigger: 'change' }],
         },
          formDataJson: {},
          dialogFormVisible:true,
          formName:{
            qykh: '',
            dsName: '',
            anyou: '',
            dlType: '',
            lycs:0,
            lyType:'',
            beiZhu:'',
            dsPhone:'',
          },
         
          wsClient: null,
          stompClient: null,
          recorder: '',
          voiceStatus: false, // 是否正在录音
          main_form: {
            chooseMicDeviceId: '', // 选择的麦克风id
          },
          Mic: [], // 可选择的麦克风
          rules: {
            file: [
              { required: true, message: "不能为空", trigger: "blur" },
            ],
          },
            LIst:[],
            recording: false,
            startTime: null,
            recordedTime: 0,
            fileList:[],
            from:{
              Text:"",
              Text1:''
            },
            socket: null,
        }
    },
    computed: {
    formattedRecordingTime() {
      let minutes = Math.floor(this.recordingTime / 60);
      let seconds = ('0' + Math.floor(this.recordingTime % 60)).slice(-2);
      return `${minutes}:${seconds}`;
    },
  },
  methods:{

    handleInput(event) {
      // 这里仅做简单演示，实际逻辑请根据需求编写
      this.from.Text1 = this.formatText(this.from.Text);
    },
    formatText(text) {
      // 对text进行你的特定转换或统计操作
      // 假设这里我们只是将其转换为大写
      return text.toUpperCase();
    }
    // getNextPrimaryKey() {
    //   return this.nextPrimaryKey++;
    // },
    
  },
  beforeRouteEnter(to, from, next) {
    // 在渲染该组件的对应路由被确认前获取数据
        next(vm => {
          vm.receivedUserName = to.query.username;
          vm.receivedUserid = to.query.userid;
        });
    },
    created() {
      // 或者在组件实例创建完成后获取数据
       if (this.$route.query.username && this.$route.query.userid) {
          this.receivedUserName = this.$route.query.username;
          this.receivedUserRole = this.$route.query.userid;
        }
        else{

        }
    },
  
  mounted() {
     // 连接到WebSocket服务器
    var socket = new WebSocket('ws://182.92.118.32:9090/ws/audio');

   socket.onopen = function(event) {
    // 连接成功，可以开始发送音频数据 
    console.log('Connected to STOMP server!');

    };

socket.onmessage = (event) => {
// 假设后端返回的数据是一个JSON对象，其中包含text属性
       var text = event.data; // 如果是纯文本，则不需要JSON.parse

      // 更新响应式数据属性
      this.from.Text = text;
    
    // 如果Vue应用中input元素绑定到了vue实例的一个属性上
    // 确保在Vue组件内使用正确的上下文(this)
    // this.$refs.input.value = text; 
};
      
      // 如果需要发送特定信息来标识连接，通常不会直接传递参数给 connect()
   
  
   this.getMic()
  },

    beforeDestroy() {
      // 组件销毁前确保关闭WebSocket连接
      if (this.socket && this.socket.readyState !== WebSocket.CLOSED) {
        this.socket.close();
      }
    },
  
    methods:{
      handleChange(value) {
        console.log(value);
      },
    submitForm(){
      // this.formDataJson = JSON.stringify(this.formName);
      // this.dialogFormVisible = false;
      // console.log(this.formDataJson + "======================================")
      this.$refs.formName.validate(valid => {
        if (valid) {
          // const selectedFormData = {
          //     fileName: JSON.stringify(this.formName.fileName),
          //     fileType: JSON.stringify(this.formName.fileType),
          //     fileSize: JSON.stringify(this.formName.fileSize),
          //     filePath: JSON.stringify(this.formName.filePath),
          //   };
          const selectedFormData = {
            qykh: this.formName.qykh,
            dsName: this.formName.dsName,
            dlType: this.formName.dlType,
            lycs: this.formName.lycs,
         };
          this.formDataJson = JSON.stringify(selectedFormData);
          this.dialogFormVisible = false;
          // console.log(this.formDataJson + "======================================")
          
        } else {
          console.log('表单验证失败');
        }
      });
    },
    // 创建WebSocket对象并连接到服务器端点
      
      sendData(blob) {
          const lyType = this.formName.lyType
          const beiZhu = this.formName.beiZhu
          const dsPhone = this.formName.dsPhone
          const timestamp = new Date().getTime();
          const fileName = `recorded_audio_${timestamp}.wav`;
          const ajTitle  = this.formDataJson
          console.log(ajTitle + "=======================================")
          const formData = new FormData();
          formData.append('audioData', blob, fileName); //formData.append('audioFile', audioBlob, fileName);
          formData.append('ajTitle',ajTitle)
          formData.append('lyType',lyType)
          formData.append('beiZhu',beiZhu)
          formData.append('userName',this.receivedUserName)
          formData.append('dsPhone',dsPhone)
          // 使用axios发送POST请求，并确保设置了正确的Content-Type
          this.$axios.post('/transcribe', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }).then(response => {
            
            
            
            // 处理服务器响应...
          });
    },


    stopRecord(){
      this.voiceStatus = false
      // this.dialogFormVisible = true
      this.$router.push({
                                path: '/FileAll',
                            });
      if (this.recorder != null) {
        let recorder = this.recorder
        

        recorder.stopRecording();
        let stream = this.stream;
        clearInterval(this.timer1);
        this.RMSList= [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0,
        ];
        stream.getAudioTracks().forEach(track => track.stop());
         // 设置mp3文件的URL到Vue组件的数据属性，以便在HTML中播放
      }
    },



    getMic(){
    let that = this;
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        // 弹框获取麦克风
        navigator.mediaDevices.getUserMedia({audio: true}).then((stream) => {
          navigator.mediaDevices.enumerateDevices().then(function (devices) {
            devices.forEach(function (device) {
              console.log(devices);
              if(device.kind === 'audioinput'){ // 麦克风
                if(device.deviceId != 'default' && device.deviceId != 'communications'){
                  that.Mic.push(device)
                }
              }
            });
          })
          stream.getTracks().forEach(track => track.stop());
        })
      }
    },
       
    startRecord(){
      this.voiceStatus = true
      // mediaDevices可提供对相机和麦克风等媒体输入设备的连接访问
      window.navigator.mediaDevices.getUserMedia(
        { audio: { deviceId: this.main_form.chooseMicDeviceId }}
        ).then((stream) => {
        this.stream = stream;
        this.getVoice()
        this.recorder = RecordRTC(stream, {
          ttype: 'audio',
          mimeType: 'audio/wav',
          recorderType: RecordRTC.StereoAudioRecorder,
          desiredSampRate: 16000,
          numberOfAudioChannels: 1, // 单声道
          timeSlice: 1000,
          // bufferSize: 4096, // 缓存大小
          ondataavailable: this.sendData,
          
        });
        this.recorder.startRecording();
      }).catch(function(err) {
        console.log(err);
        console.log('当前浏览器不支持开启麦克风!');
        that.voiceStatus = false
      });
    },
    getVoice() {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)()
      // 将麦克风的声音输入这个对象
      const mediaStreamSource = audioContext.createMediaStreamSource(this.stream)
      // 创建分析节点
      const analyserNode = audioContext.createAnalyser()
      // 连接节点
      mediaStreamSource.connect(analyserNode)
      // 可以实时听到麦克风采集的声音
      // analyserNode.connect(audioContext.destination)

      // 获取音量数据
      const dataArray = new Uint8Array(analyserNode.frequencyBinCount);

      function getVolume() {
        analyserNode.getByteFrequencyData(dataArray);
        let sum = 0;
        for (let i = 0; i < dataArray.length; i++) {
          sum += dataArray[i];
        }
        // 计算平均音量
        const averageVolume = sum / dataArray.length;
        return averageVolume;
      }
    },
      
  copyToClipboard() {
  const inputEl = this.$refs.input ? this.$refs.input.$el.querySelector('textarea') : null;
  if (this.from.Text && inputEl) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(inputEl.value).then(() => {
         this.$message({ message: '已复制', type: 'success' });
      }, () => {
        console.error('复制失败（现代API）');
        // 如果现代API失败，则尝试使用旧版API
        if (!document.queryCommandSupported || document.queryCommandSupported('copy')) {
          try {
            const hiddenTextarea = document.createElement('textarea');
            hiddenTextarea.style.position = 'absolute';
            hiddenTextarea.style.left = '-9999px';
            hiddenTextarea.value = inputEl.value;
            document.body.appendChild(hiddenTextarea);
            hiddenTextarea.select();
            document.execCommand('copy');
            document.body.removeChild(hiddenTextarea);
            console.log('内容已复制到剪贴板（使用document.execCommand方式）');
          } catch (err) {
            console.error('复制失败（旧版API）', err);
          }
        }
      });
    } else {
      // 对于不支持navigator.clipboard API的浏览器，直接尝试使用旧版API
      try {
        const hiddenTextarea = document.createElement('textarea');
        hiddenTextarea.style.position = 'absolute';
        hiddenTextarea.style.left = '-9999px';
        hiddenTextarea.value = inputEl.value;
        document.body.appendChild(hiddenTextarea);
        hiddenTextarea.select();
        document.execCommand('copy');
        document.body.removeChild(hiddenTextarea);
        console.log('内容已复制到剪贴板（使用document.execCommand方式）');
      } catch (err) {
        console.error('复制失败（旧版API）', err);
      }
    }
  } else {
    console.log('未找到文本或输入框元素为空');
  }
    },
    uploadFile(file){
        // 创建FormData对象
        let formData = new FormData();
        formData.append('file', file.file);
        // 发送POST请求到你的后端服务器API
        this.$axios.post('/B/api/transcribe', formData)
          .then(response => {
            // 处理成功回调，将返回的文件信息添加到fileList中显示
            // this.fileList.push({
            //   name: file.name,
            //   url: response.data.url, // 根据后端实际返回的数据结构调整
            //   status: 'success'
            // });
            console.log(response.data)
            this.LIst=response.data;
            console.log("============================================",)
            this.from.Text = this.LIst.map(item => item.Text);
            console.log(this.from)
          
          })
          .catch(error => {
            console.error(error);
            // 处理错误回调
          });
        },
        beginly() {

          
        
        },
    updateRecordingTime() {
      this.recordingTime = Math.round((this.endTime - this.startTime) / 1000); // 单位：秒
      },
      handleDataAvailable(event) {
      this.chunks.push(event.data);
    },
       },
        // 上传录音文件
        
      // 开始录音
      
    
    }
      
    
</script>

<style>
</style>