<template>
    <div style="height:100vh">
        
        <el-row>
            <el-col :span="6">
                <span>录音操作</span>
            </el-col>
            <el-col :span="6">
                <span></span>
            </el-col>
            <el-col :span="6">
                <span></span>
            </el-col>
            <el-col :span="6">
                <span></span>
            </el-col>
        </el-row>
        <el-form :model="form" :rules="rules" ref="form" class="demo-ruleForm">
        <el-row>
            <el-col :span="8">
                <span style="color:black;font-weight:bold" class="parent">客户名称：</span>
            </el-col>
            <el-col :span="15">
                <el-form-item prop="khName">
                <el-input v-model="form.khName" placeholder="请输入客户名称" clearable></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="8">
                <span style="color:black;font-weight:bold" class="parent">客户手机号：</span>
            </el-col>
            <el-col :span="15">
                <el-form-item prop="khPhone">
                <el-input v-model="form.khPhone" placeholder="请输入客户手机号" clearable></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="8">
                <span style="color:black;font-weight:bold" class="parent">关键词：</span>
            </el-col>
            <el-col :span="15">
                <el-form-item prop="guanjiance">
                <el-input v-model="form.guanjiance" placeholder="请输入关键词" clearable></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="8">
                <span style="color:black;font-weight:bold" class="parent">录音标题：</span>
            </el-col>
            <el-col :span="15">
                <el-form-item prop="lyTitle">
                <el-input v-model="form.lyTitle" placeholder="请输入录音标题" clearable></el-input>
                </el-form-item>
            </el-col>
        </el-row>  
        <el-row>
            <el-col :span="8">
                <span style="color:black;font-weight:bold" class="parent">录音类型：</span>
            </el-col>
            <el-col :span="15">
                <el-form-item prop="luYinType">
                <el-select v-model="form.luYinType" placeholder="请选择录音类型" style="width:100%" clearable>
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
                </el-form-item>
            </el-col>
        </el-row> 
        <el-row>
            <el-col :span="8">
                <span style="color:black;font-weight:bold" class="parent">录音概要：</span>
            </el-col>
            <el-col :span="15">
                <el-form-item prop="description">
                <el-input v-model="form.description" placeholder="请输入录音概要" clearable></el-input>
                </el-form-item>
            </el-col>
        </el-row> 
        </el-form> 
        <el-divider></el-divider>
        <el-row>
            <el-col :span="6">
                <span>{{ (recorder.duration || 0) .toFixed(1) }}: /秒</span>
            </el-col>
            <el-col :span="6">
                 <span><el-button type="warning"  @click="startRecordAudio">开始录音</el-button></span>
            </el-col>
            <el-col :span="6">
                <span ><el-button type="info" @click="toggleRecordAudio" :disabled="!isRecording">{{ toggleButtonText }}</el-button></span>
            </el-col>
            <el-col :span="6">
                <span ><el-button type="danger" @click="stopRecordAudio">停止录音</el-button></span>
            </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row>
            <el-col :span="6">
                <span>录音列表</span>
            </el-col>
            <el-col :span="6">
                 <el-input
                        placeholder="请输入客户名称"
                        v-model="cx.khName"
                        clearable>
                    </el-input>
            </el-col>
            <el-col :span="6">
                <el-select v-model="cx.luYinType" placeholder="请选择录音类型" style="width:100%" clearable>
                      <el-option
                        v-for="item in options1"
                        :key="item.value1"
                        :label="item.label1"
                        :value="item.value1">
                      </el-option>
                    </el-select>
            </el-col>
            <el-col :span="6">
                <el-button type="primary" @click="getUAll" >搜索</el-button>
            </el-col>
        </el-row>
        <el-row> 
            <el-col :span="24">
            <el-card class="box-card" style="line-height:20px;margin-top:20px" v-for="(item, index) in items" :key="index">
                <el-row>
                    <el-col :span="12">
                        <span>客户名称：{{item.khName}}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>录音标题：{{item.lyTitle}}</span>
                    </el-col>
                    
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <span>录制日期：{{item.createTime}}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>录制时长：{{item.recorder}} /秒</span>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <span>录音描述：{{item.description}}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>
                            <el-button type="danger" @click="deleted(item.id)">删除录音</el-button>
                        </span>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <audio controls :src="item.ossFileUrl" ref="player"></audio>
                    </el-col>
                </el-row>  
            </el-card>
            </el-col>
        </el-row>
        <div>
            <el-dialog
            fullscreen:
            :close-on-click-modal="false"
            :show-close="false"
                title="是否上传"
                :visible.sync="dialogVisible"
                width="50%"
                :before-close="handleClose">
                <span>录音已结束，是否保存录音记录!</span>
                <span slot="footer" class="dialog-footer">
                    <el-row :gutter="20">
                    <el-col :span="11">
                    <el-button @click="dialogVisible1">取 消</el-button>
                    </el-col>
                    <el-col :span="10">
                    <el-button type="primary" @click="uploadWAVData">确 定</el-button>
                    </el-col>
                    </el-row>
                </span>
                </el-dialog>    
            </div>  
        <el-row>
            <el-col :span="24">
                <div style="margin-top:20px">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="cx.pageInfo.pageNumber"
            :page-sizes="[5, 10, 15, 20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100]"
            :page-size="cx.pageInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>
            </div>
            </el-col>
            <div>
                <el-dialog
                    title="提示"
                    :visible.sync="deleteVisible"
                    width="50%"
                    :before-close="handleClose">
                    <span>请确认是否删除，删除后将无法恢复！</span>
                    <span slot="footer" class="dialog-footer">
                        <el-row :gutter="20">
                        <el-col :span="11">    
                        <el-button @click="deleteVisible = false">取 消</el-button>
                        </el-col>
                        <el-col :span="10">
                        <el-button type="primary" @click="deletedd">确 定</el-button>
                        </el-col>
                        </el-row>
                    </span>
                </el-dialog>

            </div>
        </el-row>
        <el-row>
            <el-col :span="24">
            
            </el-col>
        </el-row>
    </div>
</template>

<script>
import Recorder from "js-audio-recorder";
export default {
    
    data(){
        return{
            dd:"",
            deleteVisible:false,
            isRecording: false,
            isPaused: false,
            total:0,
            dialogVisible:false,
            items:[],
            cx:{
                userName: localStorage.getItem('userName'),
                khName:"",
                luYinType:"",
                pageInfo:{
                pageSize:5,
                pageNumber: 1,
                }
            },
            toggleButtonText:"暂停录音",
            recorder: new Recorder({
                sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
                sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
                numChannels: 1, // 声道，支持 1 或 2， 默认是1
                // compiling: false,(0.x版本中生效,1.x增加中)  // 是否边录边转换，默认是false

            }),
     rules: {
                khName: [
                    { required: true, message: '请输入客户名称', trigger: 'blur' },
                ],
                khPhone: [
                    { required: true, message: '请输入客户联系方式', trigger: 'blur'},
                    { min: 11, max: 13, message: '请输入正确的手机号！', trigger: 'blur' }
                ],
                guanjiance: [
                    { required: true, message: '请输入关键词', trigger: 'blur' }
                ],
                lyTitle: [
                    { required: true, message: '请输入录音标题', trigger: 'blur' }
                ],
                luYinType: [
                    { required: true, message: '请输入录音类型', trigger: 'blur' }
                ],
                description: [
                    { required: true, message: '请输入录音概要', trigger: 'blur' }
                ],
        },
        options1:[
            {
          value1: '1',
          label1: '谈案录音'
        }, {
          value1: '2',
          label1: '邀约录音'
        }, {
          value1: '3',
          label1: '诉讼律师'
        }, {
          value1: '4',
          label1: '执行'
        }, {
          value1: '5',
          label1: '调解'
        },{
          value1: '6',
          label1: '其他'
        }
        ],
            options:[
                {
          value: '1',
          label: '谈案录音'
        }, {
          value: '2',
          label: '邀约录音'
        }, {
          value: '3',
          label: '诉讼律师'
        }, {
          value: '4',
          label: '执行'
        }, {
          value: '5',
          label: '调解'
        },{
          value: '6',
          label: '其他'
        }],
            form:{
                khName:'',
                lyTitle:'',
                description:'',
                khPhone:'',
                guanjiance:'',
                luYinType:'',
            },
        }
    },
    watch: {
    isPaused(value) {
      this.toggleButtonText = value ? '恢复录音' : '暂停录音';
    },
    },
     mounted () {
        this.getUAll();   
        },
    methods: {
        //上传wav录音数据
    uploadWAVData() {
      var wavBlob = this.recorder.getWAVBlob();
       const recordingDuration = this.recorder.duration.toFixed(1);
      // 创建一个formData对象
      var formData = new FormData()
      // 此处获取到blob对象后需要设置fileName满足当前项目上传需求，其它项目可直接传把blob作为file塞入formData
      const newbolb = new Blob([wavBlob], { type: 'audio/wav' })
      //获取当时时间戳作为文件名
      const fileOfBlob = new File([newbolb], new Date().getTime() + '.wav')
      formData.append('file', fileOfBlob)
      formData.append('khName', this.form.khName)
      formData.append('lyTitle',this.form.lyTitle)
      formData.append('description',this.form.description)
      formData.append('recorder',recordingDuration)
      formData.append('userName',localStorage.getItem('userName'))
      formData.append('khPhone',this.form.khPhone)
      formData.append('guanjiance',this.form.guanjiance)
      formData.append('lyType',this.form.luYinType)
      // 调用接口上传文件
      this.$axios.post('/mp3/oss/transcribe', formData)
          .then(response => {
            // 处理成功回调，将返回的文件信息添加到fileList中显示
            // this.fileList.push({
            //   name: file.name,
            //   url: response.data.url, // 根据后端实际返回的数据结构调整
            //   status: 'success'
            // });
            this.getUAll()
            this.dialogVisible = false,
            this.form={}
            if (this.recorder.duration !== null && typeof this.recorder.duration === 'number') {
                this.recorder.duration = null;
            }

            console.log(response.data);
          })
          .catch(error => {
            console.error(error);
            
            // 处理错误回调
          });
    },
        dialogVisible1(){
        this.dialogVisible = false
        this.form={}
        if (this.recorder.duration !== null && typeof this.recorder.duration === 'number') {
                this.recorder.duration = null;
            }
    },
        deletedd(){
        var formData = new FormData()
        formData.append("id",this.dd)
        this.$axios.post("/mp3/del",formData).then(res => {
                        console.log(res)
                        if (res.data.data) {
                        this.$message({ message: '删除成功', type: 'warning' }); 
                        this.deleteVisible = false
                        this.getUAll()
                        }
                    }).catch(err => {
                        this.myMessage("error", err)
                    })
      },
    deleted(id){
        this.dd  = id
        console.log(this.dd+"======================")
        this.deleteVisible = true
    },
        handleSizeChange(val) { //分页
                this.cx.pageInfo.pageSize = val
                this.getUAll()
            },
            handleCurrentChange(val) { //分页
                this.cx.pageInfo.pageNumber = val
                this.getUAll()
            },
        myMessage(type, message) { //操作后成功和失败的弹窗
            this.$message({
                message: message,
                type: type
            });
        },
   getUAll(){
        this.$axios.post("/mp3/queryByPage",this.cx).then(res => {
                        console.log(res)
                        if (res.data.data) {
                        
                        this.items = res.data.data.records
                        this.total = res.data.data.totalRow
                        }
                    }).catch(err => {
                        this.myMessage("error", err)
                    })
      },
  
        stopRecordAudio() {
      if(this.form.khNme == ""){
        this.$message({ message: '没有录音', type: 'warning' }); 
        return;
      }
      console.log("停止录音");
      this.dialogVisible = true
      this.recorder.stop();
      this.isRecording = false;
      this.isPaused = false;
    },

        toggleRecordAudio() {
      if (this.isPaused) {
        if (this.recorder.resume) {
          
          this.recorder.resume();
          this.toggleButtonText = '暂停录音';
          this.isPaused = false;
        }
      } else {
        if (this.recorder.pause) {
          this.recorder.pause();
          this.toggleButtonText = '恢复录音';
          this.isPaused = true;
        }
      }
    },
        //开始录音
    startRecordAudio() {
      this.$refs.form.validate((valid) => {
        if (valid) {
            Recorder.getPermission().then(
                () => {
                  console.log("开始录音");
                  this.recorder.start(); // 开始录音

                this.isRecording = true;
                this.isPaused = false;
                },
                (error) => {
                  this.$message({
                    message: "请先允许该网页使用麦克风",
                    type: "info",
                  });
                  console.log(`${error.name} : ${error.message}`);
                }
              );
          } else {
            console.log('error submit!!');
            return false;
          }
      })
    },
    }
}
</script>

<style lang="less">
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
  
</style>