<template>
    <div>
        <el-card shadow="always"> 
                <el-row :gutter="5">
                <div style="line-height:50px">
                <div style="height:10px;">
                <el-col :span="10" style="margin-top:12px">
                    <el-row>
                    <el-col :span="6">
                    <div style="width:80px">
                    <span>
                        关键字
                    </span>
                    </div>
                    </el-col>
                    <el-col :span="10">
                    <div>
                    <el-input style="width:500px;" v-model="User.ajTitle" placeholder="请输入当事人姓名或对手姓名或当事人手机号"></el-input>
                    </div>
                    </el-col>
                    
                    </el-row>
                </el-col>
                <el-col :span="6" style="margin-top:12px">
                    <el-button type="primary" @click="getUAll">案源检索</el-button>
                </el-col>
                </div>

                </div>
                </el-row>
            </el-card> 
            
          
            <div style="margin-top:3px">
              <!-- 案件名称，谈案，律师，调解，执行，通话时长，录音，录音文本，创建日期，文件类型，文件大小 -->
<el-card shadow="always">
<template>
  <el-table
    :data="tableData"
    border
    style="width: 100%">
    <el-table-column
    fixed
      width="200"
      prop="ajTitle"
      label="案件名称"
      >
    </el-table-column>
    <el-table-column prop="lyType" label="录音人类型" width="200">
      <template slot-scope="scope">
        <span v-if="scope.row.lyType == '1'">探案</span>
        <span v-else-if="scope.row.lyType == '2'">律师</span>
        <span v-else-if="scope.row.lyType == '3'">其他</span>
      </template>
    </el-table-column>
    <el-table-column
      prop="fileType"
      label="通话时长"
      width="200">
    </el-table-column>
    <el-table-column
      prop="fileSize"
      label="录音"
      width="200">
    </el-table-column>
    <el-table-column
      prop="fileType"
      label="录音文本"
      width="200">
    </el-table-column>
    <!-- <el-table-column
      prop="filePath"
      label="文件路径"
      width="200">
    </el-table-column> -->
    <el-table-column
      prop="fileContent"
      label="创建日期"
      width="200">
    </el-table-column>
    <el-table-column
      prop="ajTitle"
      label="文件类型"
      width="200">
    </el-table-column>
    
    <el-table-column
      prop="beiZhu"
      label="备注"
      width="200">
    </el-table-column>
    <el-table-column
      fixed="right"
      label="操作"
      width="200">
      <template slot-scope="scope">
        <el-button type="text" size="small" @click="save(scope.row)">修改/查看/下载</el-button>
        <!-- <el-button type="text" size="small">编辑</el-button> -->
      </template>
    </el-table-column>
  </el-table>
  </template>
            <!-- 分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="User.pageInfo.pageNumber" :page-sizes="[5, 10, 15,20]" :page-size="User.pageInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total" id="fen">
            </el-pagination> 
        </el-card>
        </div>
        <el-dialog
          title="录音文本"
          :visible.sync="dialogVisible11"
          width="50%"
          :before-close="handleClose">
          
          <el-input
            type="textarea"
            :rows="15"
            placeholder="请输入内容"
            v-model="saaa">
          </el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible11 = false">取 消</el-button>
            <el-button type="primary" @click="getupdate">确 定</el-button>
            <el-button type="primary" @click="getAll">导出到本地</el-button>
          </span>
        </el-dialog>

      <div>

      </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
          
          saaid:'',
          aaa:'',
          saaa: '',
          dialogVisible11:false,
            total:0,
            tableData:[],
            User:{
              userName: '',
              ajTitle:'',
              pageInfo:{
              pageSize:5,
              pageNumber: 1,
            },
            
            }
        }
    },
    beforeRouteEnter(to, from, next) {
    // 在渲染该组件的对应路由被确认前获取数据
        next(vm => {
          vm.receivedUserName = to.query.username;
          vm.receivedUserid = to.query.userid;
        });

    },
    created() {
      // 或者在组件实例创建完成后获取数据
       if (this.$route.query.username && this.$route.query.userid) {
          this.receivedUserName = this.$route.query.username;
          this.receivedUserRole = this.$route.query.userid;
        }
    },
    
    methods: {
      get(){
        
      },
      getAll() {
        if (this.saaa) {
        let content = this.saaa; // 获取输入框的内容
        let blob = new Blob([content], { type: 'text/plain;charset=utf-8' });

        let url = window.URL.createObjectURL(blob);
        let aLink = document.createElement('a');
        aLink.href = url;
        aLink.download = `${this.aaa}.txt`; // 自定义文件名
        document.body.appendChild(aLink);
        aLink.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(aLink);
        }, 0);

        this.dialogVisible11 = false; // 关闭对话框
      } else {
        console.log('No content to export.');
      }
        },
      save(row){
          this.saaid = row.id
          this.saaa = row.fileContent
          this.aaa = row.ajTitle
          this.dialogVisible11 = true
      },


      goEnviro(fileName){
      this.$router.push({name:"index",
      query: {fileName:fileName}})
      },


      // 分页查询
      handleSizeChange(val) { //分页
                this.User.pageInfo.pageSize = val
                this.getUAll()
            },
            handleCurrentChange(val) { //分页
                this.User.pageInfo.pageNumber = val
                this.getUAll()
            },
        myMessage(type, message) { //操作后成功和失败的弹窗
            this.$message({
                message: message,
                type: type
            });
        },
          getupdate(){
            const formData = new FormData();
            formData.append('id',this.saaid)
            formData.append('fileContent',this.saaa)
            this.$axios.post("/update1",formData).then(res => {
                                          console.log(res)
                                  if (res.data.data) {
                                      this.myMessage("success", "修改成功");
                                      this.getUAll()
                                      this.dialogVisible11 = false
                                  } else {
                                      this.myMessage("error", "修改失败");
                                  }
                              }).catch(err => {
                                  this.myMessage("error", err)
            })
        },
       getUAll(){
        // axios.post('http://127.0.0.1:9090/queryByPage').
        //     then((res) => {
            
        //     this.tableData = data.records
        //     this.Page = data.records.console
        //   })

        this.$axios.post("/queryByPage",this.User).then(res => {
                        console.log(res)
                        if (res.data.data) {
                        this.tableData = res.data.data.records
                            this.total = res.data.data.totalRow
                        }
                    }).catch(err => {
                        this.myMessage("error", err)
                    })

      },
    },
    mounted () {
      this.getUAll();
    },
    
    
}
</script>
<style>
.el-card__body, .el-main{
  padding: 5px;
}
</style>