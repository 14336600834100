<template>
  <div>
        <el-row>
            <el-col :span="24">
                    <iframe src="https://www.chuangkit.com/designtools/designindex" width="100%" style="height:100vh"></iframe>
            </el-col>
        </el-row>
  </div>
</template>

<script>
export default {
    data(){
        return{
            
            
        }
    },
    mounted () {
   
    },
    methods: {
    }

}
</script>

<style>

</style>