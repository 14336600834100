<template>
    <div>
        <el-container>
        <el-aside width="60px">
            <div style="line-height:100px">
            <div id="div1">
                <span >保存</span>
            </div>
            <div id="div1">
                <span @click="getAll">导出</span>
            </div>
            <div id="div1">
                <span>分享</span>
            </div>
            <div id="div1">
                <span>收藏</span>
            </div>
            <div id="div1">
                <span>更多</span>
            </div>
            </div>
        </el-aside>
        <el-row :gutter="10">
        <el-col :span="13" class="left-container" style="width:76.16667%">
        <el-main>
            <div style="margin-top:-54px;margin-right:909px;width:500px">
                <span>{{fileName}}</span>
            </div>
            <div v-for="(item, index) in items" :key="index" style="margin-top:50px">
            <el-row :gutter="10" style="height:29px"> 
            <el-col :span="5">
            <div>
                <span v-if="item.SpeakerId == '1'">发言人1</span>
                <span v-else-if="item.SpeakerId == '2'">发言人2</span>
            </div>
            </el-col>
            <el-col :span="19">
            <div style="width:600px">
                <el-input
                :key="`${index}-input`"
                placeholder="请输入内容"
                v-model="item.Text"
                clearable>
                </el-input>
            </div>
            </el-col>
            </el-row>
            </div>
            <!-- <div style="margin-top:50px">
               <el-row :gutter="10" style="height:29px">
                <el-col :span="5">
                <div>
                <span >发言人1</span>
                </div>
                </el-col>
                <el-col :span="10">
                  <div style="width:600px">
                  <el-input
                  :key="`${index}-input`"
                  placeholder="请输入内容"
                  v-model="ttttt"
                  clearable>
                  </el-input>
                  </div>
                </el-col>
                </el-row>                
            </div> -->
            <!-- 录音 文件内容播放跟读 -->
            <!-- <div style="margin-top:120px">     
                     <div style="height:89px">
                  
                      <audio ref="audio" :src="music_path" @error="handleError" controls></audio>
                  
                      <button @click="playOrPause">
                        {{ isPlaying ? '暂停' : '播放' }}
                      </button>
                    </div>
            </div> -->
        </el-main>
        </el-col>
        <el-col :span="10" class="right-container" style="width:251px">
          <div>
              <div>
              <el-upload
              class="upload-demo"
              action=""
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              multiple
              :limit="3"
              :on-exceed="handleExceed"
              :file-list="fileList" :http-request="uploadFile">
              <el-button size="small" type="primary" round>点击上传</el-button>
              </el-upload>
              </div>
          </div>
      </el-col>
      </el-row>
      </el-container>
    </div>
</template>
<script>
import saveAs from 'file-saver';
export default {
    
    data(){
        return{

            isPlaying:false,
            music_path :'https://zjfileoss.oss-cn-beijing.aliyuncs.com/audio-files/2024_01_22%E6%9D%8E%E4%B8%BD%E4%B8%BD-%E7%BB%8F%E6%B5%8E%E7%BA%A0%E7%BA%B7%E4%BA%8C%E8%AE%BF-%E8%B0%AD%E5%87%AF%E3%80%81%E7%94%B0%E5%A3%AB%E5%8D%9A%3D%E5%B7%B2%E7%AD%BE.mp3?Expires=1740020666&OSSAccessKeyId=LTAI5tS26cycqg79dHqrUBV6&Signature=Cbvvzf1vBUq%2BZwD82m6Ix0Md57I%3D',
            fileName: '',
            id: "1",
            items:[],
            fileList:[],
            LIst:[],
            tttt:''
        }
    },

    methods:{
      playOrPause() {
      const audio = this.$refs.audio;
      if (audio.paused) {
        audio.play();
        this.isPlaying = true;
      } else {
        audio.pause();
        this.isPlaying = false;
       }
      },
        
         getAll() {
            // 获取所有输入框的内容
            const textValues = this.items.map(item => item.Text);
            // 将数据转换为CSV格式或其他格式，然后导出
            const csvContent = textValues.join('\n');

            // 使用FileSaver.js或者其他库进行下载
            if (window.saveAs) {
            let blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, 'output.csv');
            } else {
            console.error('导出功能不支持，请确保引入了FileSaver库');
            }
        },
        uploadFile(file){
            // 这里是一个简化的基于句子得分排序的抽取式摘要实现
            function generateSummary(text, maxSentences = 3) {
            // 文本预处理：分句并标准化文本
            const sentences = text.split(/[.?!]+/).map(sentence => sentence.trim());

            // 对每个句子进行词频统计，这里仅用简单的词汇计数作为得分
            const wordFrequencies = new Map();
            sentences.forEach(sentence => {
                sentence.split(/\s+/).forEach(word => {
                if (word) {
                    word = word.toLowerCase().replace(/[\W_]/g, '');
                    wordFrequencies.set(word, (wordFrequencies.get(word) || 0) + 1);
                }
                });
            });

            // 根据每个句子中包含的高频词数量对句子进行排序
            const scoredSentences = sentences.map((sentence, index) => {
                 const score = sentence.split(/\s+/)
                .map(word => word.toLowerCase().replace(/[\W_]/g, ''))
                .filter(word => word.length > 0 && wordFrequencies.has(word)) // 确保只计算词频表中存在的单词
                .reduce((sum, currentWord) => sum + wordFrequencies.get(currentWord), 0);
                return { index, score, sentence };
            }).sort((a, b) => b.score - a.score);

            // 返回前几个最高分句子组成的大致摘要
            return scoredSentences.slice(0, maxSentences).map(item => item.sentence).join(' ');
            }
        // 创建FormData对象
        let formData = new FormData();
        formData.append('file', file.file);
        // 发送POST请求到你的后端服务器API
        this.$axios.post('/B/api/transcribe', formData)
          .then(response => {
            // 处理成功回调，将返回的文件信息添加到fileList中显示
            // this.fileList.push({
            //   name: file.name,
            //   url: response.data.url, // 根据后端实际返回的数据结构调整
            //   status: 'success'
            // });
            this.LIst=response.data;
            this.items = this.LIst.map(item => ({Text:item.Text,SpeakerId:item.SpeakerId}));
            const concatenatedText = this.items.map(item => item.Text).join(' ');
            this.tttt = generateSummary(concatenatedText)
            console.log("================="+this.tttt)
          })
          .catch(error => {
            console.error(error);
            
            // 处理错误回调
          });
        }, //签 vs 对-按-dfd-1
    },
    mounted () {
      this.fileName = this.$route.query.fileName
    }
}
</script>
<style>
.el-header, .el-footer {
    background-color: #B3C0D1;
    color: #333;
    text-align: center;
    line-height: 60px;
  }
  
  .el-aside {
    background-color: #D3DCE6;
    color: #333;
    text-align: center;
    line-height: 200px;
  }
  
  .el-main {
    background-color: #E9EEF3;
    color: #333;
    text-align: center;
    line-height: 160px;
  }
  
  body > .el-container {
    margin-bottom: 40px;
  }
  
  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }
  
  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }
  .html,body{
    width: 100%;
    height: 100%;
  }
  .div1{
    width: 100%;
    height: 60px;
  }
.right-container {
  position: fixed;
  /* 设置右侧文本区域的位置 *
  right: 0;
  background-color: white; /* 可能需要设置背景色以避免穿透 */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* 添加阴影效果 */
  z-index: 1; /* 确保其位于顶部 */
}
.element.style {
    padding-left:25px;
    padding-right:17px;
    height:700px;
}
.el-col-13{
  width: 76.16667%;
}
</style>