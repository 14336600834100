<template>
    <div>
        <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item title="开场白" name="1">
          <div style="height: 100px;margin-left:50px">
            <el-steps direction="vertical" :active="2">
              <el-step title="首咨" description="你好，我是正己律师事务所的法务，看你在网上留言，有法律案件需要咨询吗？"></el-step>
              <el-step title="回访" description="你好，我是正己律师事务所的法务，打电话回访，你之前遇到的法律案件解决了吗？"></el-step>
            </el-steps>
          </div>
        </el-collapse-item>
        <el-collapse-item title="了解案情" name="2">
          <div style="height: 600px;margin-left:50px">
            <el-steps direction="vertical" :active="2">
              <el-step title="案由" description="民间借贷/货款纠纷/工程欠款/投资理财/购房纠纷/离婚析产/投资理财/股权纠纷/交通事故/遗产继承/劳动赔偿/"></el-step>
              <el-step title="身份" description="你是当事人吗？你和对方的关系"></el-step>
              <el-step title="标的" description="你借给对方多少钱？是否约定利息？年息 24法律支持，36约定利息。"></el-step>
              <el-step title="证据" description="是否签订（合同/协议/借条......）？；是否有（视频/录音/转账记录/......）？"></el-step>
              <el-step title="时间" description="钱是什么时间借给对方的？是否起诉对方？是否拿到胜诉判决书？是否执行对方？执行是否终本？终本后有多久了？"></el-step>
              <el-step title="案情经过" description="注意共情/拱火"></el-step>
              <el-step title="当事人性格" description="需要收集的信息"></el-step>
              <el-step title="欠款人状态" description="（房产/车子/存款/生活状态/债权关系）"></el-step>
              <el-step title="欠款人借钱用途" description="家庭生活/公司资金周转/个人私用"></el-step>
              <el-step title="欠款人收入情况" description="（打工/蓝领/老板）确定还款能力"></el-step>
              <el-step title="欠款人不还钱原因" description="您有分析过吗？"></el-step>
            </el-steps>
          </div>
        </el-collapse-item>
        <el-collapse-item title="扩风险 （共情/拱火）" name="3">
          <div style="height: 600px;margin-left:50px">
            <el-steps direction="vertical" :active="1" process-status ='error'>
              <el-step title="诉讼时效" description="诉讼时效是三年，追回欠款会有黄金诉讼期，过了诉讼时效律师也很难帮您要回欠款了"></el-step>
              <el-step title="恶意拖欠" description="你借钱给对方的时候特痛快，现在对方就是有钱不给你，耍无赖；你还不好意思向对方开口要钱。对方心理肯定还笑话你傻"></el-step>
              <el-step title="对方失联" description="对方失联就是向你声明：钱我不还了，你爱咋地咋地。就代表你的钱打水漂了"></el-step>
              <el-step title="财产转移" description="我们要和对方抢时间，对方争分夺秒的在转移资产；我们要在他转移资产前起诉他做财产保全；避免对方把钱转移走导致咱们没
有可执行的资产；避免咱们的钱打水漂"></el-step>
              <el-step title="他人起诉" description="对方有50万，谁先起诉他拿到判决书，先去执行他的财产就先还谁钱了。咱们不抓紧时间起诉他轮到咱们就没有可执行的财产了"></el-step>
              <el-step title="是否失信" description="对方现在是否失信/是否死了/"></el-step>
              <el-step title="执行案件" description="对方现在有没有涉诉案件在身/对方现在有没有被执行的资产/对方有没有偿还能力/"></el-step>
            </el-steps>
          </div>
        </el-collapse-item>
        <el-collapse-item title="给信心 (律所可以为你做什么事+一个案例)" name="4">
          <div style="height: 400px;margin-left:50px">
            <el-steps direction="vertical" :active="1">
              <el-step title="保全" description="4月接待一个浙江杭州的货款纠纷的案子，标的500万；咱们律师捕捉到对方的财产信息，10天就跟法院沟通配合做了诉前保全，直接冻结了
对方1000万的资产，快速拿到谈判筹码，对方很快一笔付清了500万的欠款。"></el-step>
              <el-step title="时效" description="2023年12月的一个借贷纠纷的案子，当事人不着急说要春节后启动案件，我们多次提醒当事人诉讼时效问题，当事人一直不当回事春节后
找到我们，我们一查诉讼时效过了20天了，结果50万打水漂了"></el-step>
              <el-step title="谈判" description="怀柔一个80多万的个人借贷的案子，我们律师捕捉到对方财产线索，我们律师专门去了怀柔配合当事人和对方谈判，当天对方全部偿还欠
款给当事人。从委托到回款只用18天。至今这哥当时还和我联系，已经成为好朋友了。"></el-step>
              <el-step title="财产转移" description="我们要和对方抢时间，对方争分夺秒的在转移资产；我们要在他转移资产前起诉他做财产保全；避免对方把钱转移走导致咱们没
有可执行的资产；避免咱们的钱打水漂"></el-step>
              <el-step title="执行" description="我们正己律所有北京非常稀有的一直专业做执行的律师团队，团队是由具有多年经侦经验的律师组成；什么案子都不接，只接执行终本的案
子。团队主攻方向就是根据你的案件情况捕捉有效线索后开始全国大范围的财产搜查，被执行人相关联的所有财产信息。有无财产转移/有无突破
连带责任人的可能，全部追踪一便。"></el-step>
            </el-steps>
          </div>
        </el-collapse-item>
        <el-collapse-item title="讲实力" name="5">
          <div style="height: 400px;margin-left:50px">
            <el-steps direction="vertical" :active="1">
              <el-step title="律所性质" description="执行律所；重在执行"></el-step>
              <el-step title="办公环境" description="四惠东地铁附近，通惠河南岸，独栋办公楼，上下5层6000平办公室。"></el-step>
              <el-step title="律所团队" description="调解谈判团队/诉讼律师团队/执行团队为咱们当事人保驾护航，"></el-step>
              <el-step title="聘请外援" description="聘请退休法官坐镇正己律所，针对复杂的案件法官带领律师进行头脑风暴分析案件寻找突破口；更快更好的帮助当事人追回欠款，结案。"></el-step>
              <el-step title="荣誉勋章" description="律所全心全力的付出帮助当事人解决难题得到当事人的认可，律所收到当事人表扬&锦旗。"></el-step>
              <el-step title="执行团队" description="正己律所服务宗旨区别于其他律所，律所最终目标是帮助当事人拿到欠款，不是帮助当事人拿到胜诉判决书“胜诉不执行，一切等于零”
整个北京有独立执行团队的律所不超过3家，咱们正己律所就是其中一家"></el-step>
            </el-steps>
          </div>
        </el-collapse-item>
        <el-collapse-item title="价格铺垫" name="6">
          <div style="height: 800px;margin-left:50px">
            <el-steps :active="1" direction="vertical">
              <el-step title="正面回应" >
                <template #description>
                  <div style="height: 200px;margin-left:60px">
                    <el-steps :active="1" direction="vertical">
                      <el-step title="咨询目的">
                        <template #description>
                          <div style="height: 200px;margin-left:60px">
                            <el-steps :active="active" direction="vertical">
                              <el-step title="您的关注点是付费，还是要解决案子？">
                              </el-step>
                              <el-step title="是否咨询过其他律所？是否报价？"></el-step>
                              <el-step title="子主题 3"></el-step>
                            </el-steps>
                          </div>
                        </template>
                      </el-step>
                      <el-step title="回应态度" description = '专业/利他/自信/干脆'></el-step>
                      <el-step title="非要报价" >
                        <template #description>
                          <div style="margin-left:60px;height: 250px">
                            <el-steps :active="active" direction="vertical">
                              <el-step title="组合报价">
                                <template #description>
                                  <div style="margin-left:60px">
                                    <el-steps :active="active" direction="vertical">
                                      <el-step title="标的金额"></el-step>
                                      <el-step title="委托内容/委托模式"></el-step>
                                      <el-step title="难易程度"></el-step>
                                      <el-step title="举例子："></el-step>
                                      <el-step title="投入工作量"></el-step>
                                    </el-steps>
                                  </div>
                                </template>
                              </el-step>
                              <el-step title="区间报价">
                                <template #description>
                                  <div style="margin-left:60px">
                                    <el-steps :active="active" direction="vertical">
                                      <el-step title="小于2万 必须报价"></el-step>
                                      <el-step title="2万-10万 不报价"></el-step>
                                      <el-step title="10万-50万 组合报价"></el-step>
                                      <el-step title="50万以上 组合报价"></el-step>
                                    </el-steps>
                                  </div>
                                </template>
                              </el-step>
                            </el-steps>
                          </div>
                        </template>
                      </el-step>
                    </el-steps>
                  </div>
                </template>
              </el-step>
              <el-step title="回应不回答" description="我们是一般代理和半风险方式两种方式（针对当事人的案件类型，举同类型的例子，难度更大，标的更大的）">
              </el-step>
            </el-steps>
          </div>
        </el-collapse-item>
        <el-collapse-item title="邀约到所" name="7">
          <div style="height: 400px;margin-left:50px">
            <el-steps direction="vertical" :active="1">
              <el-step title="第一" description="您这个钱想不想要？第二：您想要钱，咱们律所知道怎么帮您要钱；具体如何帮您要钱，需要您带着资料到律所和负责您同
类型的案件的主任面对面沟通。你觉得咱们专业再谈收费的事。"></el-step>
              <el-step title="精确时间" description="今天是周日/明天是周一；主做（同类型案件的）大主任正好在所里，刚刚看了主任案件排期，今天下午3点以后有档期，
你今天下午可以过来吗？3点之前没有时间了3点之后可以，暂定4点，您带着详细的资料过来，让主任对案件做研判评估。"></el-step>
              <el-step title="确认身份" description="一定是当事人，避免无效邀约"></el-step>
              <el-step title="经营愧疚感" description="叮嘱当事人，一定要提前安排好时间，带好资料，约定时间主任就不接待其他当事人了。"></el-step>
              <el-step title="路线规划" description="开车有停车场/公交发送定位和步行路线图"></el-step>
              <el-step title="致电确认" description="到访前要电话确认，是否在路上，资料是否带齐全"></el-step>
            </el-steps>
          </div>
        </el-collapse-item>
        <el-collapse-item title="到所接待" name="8">
          <div style="margin-left:50px">
            <el-steps direction="vertical" :active="1" process-status="finish">
              <el-step title="逛所" description="介绍律所，重点介绍团队和锦旗部分"></el-step>
            </el-steps>
          </div>
        </el-collapse-item>
        <el-collapse-item title="不紧迫客户话术" name="9">
          <div style="height: 600px;margin-left:50px">
            <el-steps direction="vertical" :active="1">
              <el-step title="拱火" description="（当事人性格、跟对手的关系、当事人经济能力，家属的看法，对手的态度，对手的经济偿
还支付能力，诉讼时效，财产转移）"></el-step>
              <el-step title="直球（直球怼他）" description="反问客户为什么拖延？（个人原因/对手原因/案子本身）"></el-step>
              <el-step title="根据案情扩风险">
                <template #description>
                          <div style="height: 200px;margin-left:60px">
                            <el-steps :active="active" direction="vertical">
                              <el-step title="每一年的追要记录是否持续，对方会进行有准备的抗辩"></el-step>
                              <el-step title="支付能力"></el-step>
                              <el-step title="对手因不可抗力去世（举例），诉讼阻碍大，牵扯到继承（ICU重症还活着的时候立案）"></el-step>
                              <el-step title="对手出境跑路"></el-step>
                              <el-step title="矢口否认。时间长会不认账、证据缺失"></el-step>
                              <el-step title="拖时间久了。对合同已经默认了，在法庭上主张得不到支持"></el-step>
                            </el-steps>
                          </div>
                        </template>
              </el-step>
              <el-step title="举例子（给甜头），举例子（拖就要不回来了）" description="---对方一定会规避、有准备的抗辩，是否累计每一年都追诉"></el-step>
              <el-step title="给信心" description="举例/解决方案 失败案例找我们处理成功、成功案例"></el-step>
              <el-step description="包装律所、主任"></el-step>
            </el-steps>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
</template>
<script>
export default {
    
    
   data() {
      return {
        activeNames: ['1', '2','3','4','5','6','7','8','9'],
      };
    },
    methods: {
      handleChange(val) {
        console.log(val);
      }
    }
}
</script>
<style lang="scss" scoped>

</style>