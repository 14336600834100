<template>
  <div>
    <iframe src="https://tingwu.aliyun.com/home" width="100%" style="height:100vh"></iframe>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>