<template>
    <div>
        <el-dialog  :visible.sync="dialogFormVisible">
        <el-dialog>
            <div></div>
            </el-dialog> 
            <el-card shadow="always">
            <el-form ref="form" :model="form" label-width="80px" :rules="rules">
            <el-form-item label="用户" prop="userName">
                <el-input v-model="form.userName"></el-input>
            </el-form-item>
           
                <el-form-item label="密码" prop="userPassword">
                <el-input v-model="form.userPassword">
                </el-input>
                </el-form-item>
            </el-form>
            </el-card>
            <div>
                <div class="s-canvas">
                    <canvas id="s-canvas" :width="contentWidth" :height="contentHeight">dsd</canvas>
                </div>
            </div>
            <div>
            <el-button type="warning" style="margin-right:35px;margin-top:22px;width:338px" @click="login">登录</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data(){
        return{
            userName1: '',
            dialogFormVisible:true,
            form:{
                userName:'',
                userPassword: ''
            },
            rules: {
                    userName: [
                        { required: true, message: '请输入账号', trigger: 'change' },
                    ],
                    userPassword: [
                        { required: true, message: '请输入密码', trigger: 'change' },
                    ],
            }

        }
    },
     methods:{
        myMessage(type, message) { //操作后成功和失败的弹窗
            this.$message({
                message: message,
                type: type
            });
        },
        login(){
            this.$axios.post("/login",this.form).then(res => {
                        console.log(res)
                        if(res.data.message == "1"){
                                this.$message({ message: '用户不存在', type: 'success' }); 
                            }
                            if(res.data.message == "2"){
                                this.$message({ message: '密码或账号有误', type: 'success' }); 
                            }
                        if (res.data.data) {
                               this.userName1 = res.data.data.userName
                               console.log(this.userName1 + "=======================================")
                                this.$router.push({
                                path: '/text1',
                                query: { userName: this.userName1 }
                            });
                        }
                    }).catch(err => {
                        this.myMessage("error", err)
                    })
        }
     }
}
</script>
<style>

.s-canvas {
 height: 47px;
}
.s-canvas canvas{
 margin-top: 1px;
 margin-left: 8px;
}
.dfd{
    width: 100px;
    height: 100px;
    background: rgb(184, 49, 49);
}
@media screen and (max-width: 768px) {
    .my-element {
      width: 100%;
    }
  }
</style>