<template>
  <div>     
            <el-card shadow="never" style="width:100%;height:100vh">
                <div>
                    <el-row :gutter="10">
                        <el-col :span="16">
                        <el-row>
                            <el-col :span="3">
                                <span>姓名</span>
                            </el-col>
                            <el-con :span="5">
                                <el-input v-model="cx.wtName"></el-input>
                            </el-con>
                        </el-row>
                        </el-col>
                        <el-col :span="4">
                            <el-button type="primary" @click="getUAll">确 定</el-button>
                        </el-col>
                        <el-col :span="4">
                            <el-button type="text" @click="dialogFormVisible = true">新建话题</el-button>
                        </el-col>
                    </el-row>
                </div>
                <div>
                    <el-dialog title="收货地址" :visible.sync="dialogFormVisible">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="话题：" prop="wtName">
                        <el-input v-model="ruleForm.wtName"></el-input>
                    </el-form-item>
                    <el-form-item label="标题：" prop="hsName">
                        <el-input v-model="ruleForm.hsName"></el-input>
                    </el-form-item>
                    <el-form-item label="创建人：" prop="cjrName">
                        <el-input v-model="ruleForm.cjrName"></el-input>
                    </el-form-item>
                    <el-form-item label="分类：" prop="flType">
                        <el-select v-model="ruleForm.flType" clearable placeholder="请选择">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button @click="dialogFormVisible = false">取 消</el-button>
                        <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
                    </div>
                    </el-dialog>
                </div>

                <div>
                    <el-dialog title="查看详情" :visible.sync="dialogForm">
                        <div>
                            <el-card shadow="never">
                              <el-form :model="ruleForm1" :rules="rules" ref="ruleForm1" label-width="100px" class="demo-ruleForm">
                                <el-form-item label="话题：" prop="wtName">
                                    <el-input v-model="ruleForm1.wtName"></el-input>
                                </el-form-item>
                                <el-form-item label="标题：" prop="hsName">
                                    <el-input v-model="ruleForm1.hsName"></el-input>
                                </el-form-item>
                                <el-form-item label="创建人：" prop="cjrName">
                                    <el-input v-model="ruleForm1.cjrName"></el-input>
                                </el-form-item>
                                <el-form-item label="分类：" prop="flType">
                                    <el-select v-model="ruleForm1.flType" clearable placeholder="请选择">
                                        <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-form>
                            </el-card>
                        </div>
                        <div style="margin-top:20px">
                            <el-card shadow="never">
                              <div class="user">
                                <span>评论区</span>
                                <!-- @keyup监听键盘事件 -->
                                <!-- @keyup.enter回车键获取值 -->
                                <el-row>
                                <el-col :span="12">
                                <div style="line-height:18px">
                                <el-row :gutter="10">
                                <el-col :span="5">
                                    <div>
                                        <span>用户名:</span>
                                    </div>
                                </el-col>
                                <el-col :span="10">
                                <input v-model.trim="user" @keyup.enter="addItem()" type="text" />
                                </el-col>
                                </el-row>
                                <el-row>
                                <el-col :span="5">
                                <p>评论内容:</p>
                                </el-col>
                                <el-col :span="10">
                                <p><textarea v-model="comm" @keyup.enter="addItem()" placeholder="吐槽内容"></textarea></p>
                                </el-col>
                                <button type="button" @click="btn" style="margin-top:29px">提交</button>
                                </el-row>
                                </div>
                                </el-col>
                                <el-col :span="12">
                                <div>
                                    <el-row :gutter="10">
                                    <el-col :span="5">
                                     <span>吐槽回复:</span>
                                    </el-col>
                                    <el-col :span="14">
                                    <ul v-for="(item,index) in list" :key="index">
                                        <li>{{item.name}}说：{{item.txt}}<a href="#" @click="delItem(item)" style="margin-left:30px">删除</a></li>
                                    </ul>
                                    </el-col>
                                    </el-row>
                                </div>
                                </el-col>
                                </el-row>
                                </div>
                            </el-card>
                        </div>
                    </el-dialog>
                </div>
                
                <div>
                    <template>
                <el-table
                    :data="tableData"
                    border
                    style="width: 100%">
                    <el-table-column
                    fixed
                    prop="wtName"
                    label="话题"
                    width="150">
                    </el-table-column>
                    <el-table-column
                    prop="hsName"
                    label="标签"
                    width="120">
                    </el-table-column>
                    <el-table-column
                    prop="cjrName"
                    label="创建人"
                    width="120">
                    </el-table-column>
                    <el-table-column
                    prop="flType"
                    label="分类"
                    width="120" :formatter="formatStatus">
                    <template slot-scope="scope">
                        <span v-show="scope.row.flType==1">邀约话术</span>
                        <span v-show="scope.row.flType==2">探案话术</span>
                        <span v-show="scope.row.flType==3">调解话术</span>
                        <span v-show="scope.row.flType==4">客诉话术</span>
                    </template>
                    </el-table-column>
                    <el-table-column
                    prop="createTime"
                    label="创建日期"
                    width="300">
                    </el-table-column>
                    <el-table-column
                    prop="ckcs"
                    label="查看次数"
                    width="120">
                    </el-table-column>
                    <el-table-column
                    prop="dzcs"
                    label="点赞次数"
                    width="120">
                    </el-table-column>
                    <el-table-column
                    fixed="right"
                    label="操作"
                    width="100">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="sava(scope.row)">查看</el-button>
                        <el-button type="text" size="small" @click="dz">点赞</el-button>
                    </template>
                    </el-table-column>
                </el-table>
                <div>
                    <el-popover
                            placement="bottom-end"
                            width="160"
                            v-model="visible">
                            <p>这是一段内容这是一段内容确定删除吗？</p>
                            <div style="text-align: right; margin: 0">
                                <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                                <el-button type="primary" size="mini" @click="visible = false">确定</el-button>
                            </div>
                    </el-popover>
                </div>
                </template>
                </div>
                <div style="margin-top:20px">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="cx.pageInfo.pageNumber"
            :page-sizes="[5, 10, 15, 20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100]"
            :page-size="cx.pageInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>
            </div>
            </el-card>
  </div>
</template>

<script>
export default {
    el:".box",
    data(){
        return{
            visible: false,
            ruleForm1:{

            },
			// 评论用户以及内容的数据
			// name评论的用户名字 txt评论的内容
			list:[
				],
				user:"",//输入的名字存入
				comm:"",//评论存入
            dialogForm:false,
            options:[
                {
                value: '1',
                label: '邀约话术'
                }, {
                value: '2',
                label: '探案话术'
                }, {
                value: '3',
                label: '调解话术'
                }, {
                value: '4',
                label: '客诉话术'
                }
            ],
            ruleForm:{

            },
            dialogFormVisible:false,
            tableData:[],
            cx:{
            wtName:'',
              pageInfo:{
              pageSize:5,
              pageNumber: 1,
            }
        },
        }
    },
    mounted () {
    this.getUAll();   
    },
    methods: {
        dz(){
            this.visible = true
        },
        sava(row){
            this.dialogForm = true
            this.ruleForm1 = this.ruleForm1 = Object.assign({}, row);
        },
    // 点击按钮提交
					btn(){
						var usercom={
							name:this.user,
							txt:this.comm
						}
						this.list.unshift(usercom)
						this.user="";
						this.comm="";
					},
					// 用户名方法事件
					addItem(){
						// 创建用户名
						var item={
							// 用户名字
						name:this.user,
						// 用户评论
						txt:this.comm,
						};
						
						// 在list前面添加 item    unshift在前面添加
						this.list.unshift(item)
						
						// 清空user
						this.user="";
						// 清空评论
						this.comm="";
					},
					//删除点击事件  获取元素
					delItem(item){
						// 查找元素item在list里的下标
						var ind=this.list.findIndex(value=>value.name==item.name);
						//删除
						// splice 功能（从第几个元素，删除几个 添加内容）
						this.list.splice(ind,1);
					},
    handleSizeChange(val) { //分页
                this.cx.pageInfo.pageSize = val
                this.getUAll()
            },
            handleCurrentChange(val) { //分页
                this.cx.pageInfo.pageNumber = val
                this.getUAll()
    },
    myMessage(type, message) { //操作后成功和失败的弹窗
            this.$message({
                message: message,
                type: type
            });
        },
    getUAll(){
        this.$axios.post("/message/queryByPage",this.cx).then(res => {
                        console.log(res)
                        if (res.data.data) {
                        this.tableData = res.data.data.records
                        this.total = res.data.data.totalRow
                        }
                    }).catch(err => {
                        this.myMessage("error", err)
                    })
      },
    }
}
</script>

<style>
</style>