<template>
  <div>
    <!--  -->
    <el-row>
        <el-col :span="24"></el-col>
        </el-row>
        <el-row>
        <el-col :span="12"></el-col>
        <el-col :span="12"></el-col>
        </el-row>
        <el-row>
        <el-col :span="8"></el-col>
        <el-col :span="8"></el-col>
        <el-col :span="8"></el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-card shadow="always" style="height:100vh">
                    <div>
                    <el-row>
                    <el-col :span="7">
                        <div><span>.</span></div>
                    </el-col>
                    <el-col :span="8">
                    <el-input 
                        placeholder="请输入客户名称"
                        v-model="cx.khName"
                        clearable>
                    </el-input> 
                    </el-col>
                    <el-col :span="3">
                    <el-button type="primary" @click="getUAll">搜索按钮</el-button>
                    </el-col>
                    </el-row>
                    </div>
                    <div>
                        <el-card class="box-card" v-for="(item, index) in items" :key="index">
                        <div style="margin-top:40px">
                        <span style="font-size:23px;color:black;font-weight:bold;margin-right:905px">{{item.khName+"--"+item.lyTitle+"--"+item.createTime}}</span>
                        <el-row :gutter="10"> 
                        <br>
                        <el-col :span="10">
                        <span style="margin-right:257px;">客户名称：{{item.khName}}</span>
                        </el-col>
                        <el-col :span="10">
                        <span>录音标题：{{item.lyTitle}}</span>
                        </el-col>
                        </el-row>
                        <el-row :gutter="10">
                        <br>
                        <el-col :span="10">
                        <span style="margin-right:211px;">录制日期：{{item.createTime}}</span>
                        </el-col>
                        <el-col :span="10">
                        <span style="">录制时长：{{item.recorder}} /秒</span>
                        </el-col>
                        </el-row>
                        <el-row  :gutter="10">
                        <br>
                        <el-col :span="10">
                        <span style="margin-right:270px;">录音描述：{{item.description}}</span>
                        </el-col>
                        <el-col :span="10">
                        
                        </el-col>
                        </el-row>
                        <el-row :gutter="20" style="line-height:114px">
                            <el-col :span="18">
                            <div>
                                <audio controls :src="item.ossFileUrl" ref="player" style="width:86%;margin-top:31px;margin-left:39px"></audio>
                            </div>
                            </el-col>
                            <!-- <el-col :span="4">
                            <el-row :gutter="10">
                            <el-col :span="11">
                            <el-button type="danger" @click="deleted(item.id)">删除录音</el-button>
                            </el-col>
                            <el-col :span="5">
                            <el-button type="success" @click="update1(item)">修改信息</el-button>
                            </el-col>
                            </el-row>
                            </el-col> -->
                            
                        </el-row>
                        </div>
                        </el-card>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <el-row>
        <el-col :span="4"></el-col>
        <el-col :span="4"></el-col>
        <el-col :span="4"></el-col>
        <el-col :span="4"></el-col>
        <el-col :span="4"></el-col>
        <el-col :span="4"></el-col>
    </el-row>
  </div>
</template>

<script>
export default {
    data(){
        return{
            items:[],
            cx:{
                userName: localStorage.getItem('userName'),
                khName :''
            }

        }
    },
    mounted () {
    this.getUAll();   
    },
    methods: {
        myMessage(type, message) { //操作后成功和失败的弹窗
            this.$message({
                message: message,
                type: type
            });
        },
        getUAll(){
        this.$axios.post("/mp3/queryByPage1",this.cx).then(res => {
                        console.log(res)
                        if (res.data.data) {
                        this.items = res.data.data
                        this.getUAll()
                        }
                    }).catch(err => {
                        this.myMessage("error", err)
                    })
      },
    }

}
</script>

<style>
.html,body{
    height: 100vh;
}
.el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
</style>