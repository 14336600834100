<template>
    <div ref="home" class="homeM">
    <!-- 创建 -->
    <div>
    <el-card shadow="never" style="line-height:51px">
    <el-row>
      <el-col :span="10">
        <el-row :gutter="10">
        <el-col :span="14">
         <div style="color:black;margin-right:13%;font-weight:bold" class="div2">录音操作</div>
        </el-col>
        </el-row>
      </el-col>
      </el-row>
        <el-divider style="background-color: red;"></el-divider>
        <div style="width:1945px">
        <el-form :model="form" :rules="rules" ref="form" class="demo-ruleForm">
        <div>
        <el-row :gutter="10">
            <el-col :span="3">
             <span style="color:black;font-weight:bold" class="parent">客户名称：</span>
            </el-col>
            <el-col :span="12">
            <el-form-item prop="khName">
               <el-input v-model="form.khName" placeholder="请输入客户名称" clearable></el-input>
            </el-form-item>
            </el-col>
        </el-row>
        </div>
        <div>
        <el-row :gutter="10">
            <el-col :span="3">
             <span style="color:black;font-weight:bold" >客户手机号：</span>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="khPhone">
             <el-input v-model="form.khPhone" placeholder="请输入客户手机号" clearable></el-input>
              </el-form-item>
            </el-col>
        </el-row>
        </div>
        <div>
        <el-row :gutter="10">
            <el-col :span="3">
             <span style="color:black;font-weight:bold">关键词：</span>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="guanjiance">
             <el-input v-model="form.guanjiance" placeholder="请输入关键词" clearable></el-input>
              </el-form-item>
            </el-col>
        </el-row>
        </div>
        <div>
        <el-row :gutter="10">
            <el-col :span="3">
             <span style="color:black;font-weight:bold">录音标题：</span>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="lyTitle">
             <el-input v-model="form.lyTitle" placeholder="请输入录音标题" clearable></el-input>
              </el-form-item>
            </el-col>
        </el-row>
        </div>
        <div>
        <el-row :gutter="10">
            <el-col :span="3">
             <span style="color:black;font-weight:bold">录音类型：</span>
            </el-col>
            <el-col :span="12">
            <el-form-item prop="luYinType">
             <el-select v-model="form.luYinType" placeholder="请选择" style="width:100%" clearable>
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            </el-col>
        </el-row>
        </div>
        <div>
        <el-row :gutter="10">
            <el-col :span="3">
             <span style="color:black;font-weight:bold">录音概要：</span>
            </el-col>
            <el-col :span="12">
            <el-form-item prop="description">
             <el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="form.description" clearable>
            </el-input>
            </el-form-item>
            </el-col>
        </el-row>
        </div>
        </el-form>
        </div>
        <el-divider style="background-color: red;"></el-divider>
        <div>
          <div>
        <div style="margin-top:21px">
            <el-row :gutter="10" style="line-height:2px;margin-right:8%">
                <el-col :span="5" style="margin-left:13px">
                    <!-- {{ (recorder.duration || 0).toFixed(1) }}  -->
                <h3>录音时长：{{ (recorder.duration || 0) .toFixed(1) }}: /秒</h3>
                </el-col>
                <el-col :span="10">
                <el-button type="warning"  @click="startRecordAudio">开始录音</el-button>
                <el-button type="info" @click="toggleRecordAudio" :disabled="!isRecording">{{ toggleButtonText }}</el-button>
                <el-button type="danger" @click="stopRecordAudio">停止录音</el-button>
                </el-col>  
            </el-row>
            <div>
            <el-dialog
            fullscreen:
            :close-on-click-modal="false"
            :show-close="false"
                title="是否上传"
                :visible.sync="dialogVisible"
                width="30%"
                :before-close="handleClose">
                <span>录音已结束，是否保存录音记录!</span>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible1">取 消</el-button>
                    <el-button type="primary" @click="uploadWAVData">确 定</el-button>
                </span>
                </el-dialog>    
            </div>    
        </div>
    </div>
        </div>
    </el-card>
    </div>

    <el-card shadow="never" style="margin-top:1px">
    <div>
        <el-row :gutter="10">
            <el-col :span="6">
            <span class="span1" >录音列表</span>
            </el-col>
            <el-col :span="15">
                <div>
                  <el-row :gutter="10" style="margin-right:1px"> 
                    <el-col :span="10">
                    <el-input
                        placeholder="请输入客户名称"
                        v-model="cx.khName"
                        clearable>
                    </el-input>
                    </el-col>
                    <el-col :span="10">
                    <el-select v-model="cx.luYinType" placeholder="请选择录音类型" style="width:100%" clearable>
                      <el-option
                        v-for="item in options1"
                        :key="item.value1"
                        :label="item.label1"
                        :value="item.value1">
                      </el-option>
                    </el-select>
                    </el-col>
                    <el-col :span="4" class="button1">
                        <el-button type="primary" @click="getUAll" >搜索</el-button>
                    </el-col>
                  </el-row>
                </div>
            </el-col>
        </el-row>

    </div> 
    <div>
        <div style="line-height:30px;">
            <el-card class="box-card" style="line-height:20px;margin-top:20px" v-for="(item, index) in items" :key="index">
              <div style="margin-top:40px">
              <span style="font-size:23px;color:black;font-weight:bold;margin-right:905px">{{item.khName+"--"+item.lyTitle+"--"+item.createTime}}</span>
              <el-row :gutter="10"> 
              <br>
              <el-col :span="10">
              <span style="margin-right:257px;">客户名称：{{item.khName}}</span>
              </el-col>
              <el-col :span="10">
              <span>录音标题：{{item.lyTitle}}</span>
              </el-col>
              </el-row>
              <el-row :gutter="10">
              <br>
              <el-col :span="10">
              <span style="margin-right:211px;">录制日期：{{item.createTime}}</span>
              </el-col>
              <el-col :span="10">
              <span style="">录制时长：{{item.recorder}} /秒</span>
              </el-col>
              </el-row>
              <el-row  :gutter="10">
              <br>
              <el-col :span="10">
              <span style="margin-right:270px;">录音描述：{{item.description}}</span>
              </el-col>
              <el-col :span="10">
              
              </el-col>
              </el-row>
              <el-row :gutter="20" style="line-height:114px">
                <el-col :span="18">
                <div>
                    <audio controls :src="item.ossFileUrl" ref="player" style="width:86%;margin-top:31px;margin-left:39px"></audio>
                </div>
                </el-col>
                <el-col :span="4" style="line-height:60px">
                <el-row :gutter="10">
                <el-col :span="11">
                <el-button type="danger" @click="deleted(item.id)">删除录音</el-button>
                </el-col>
                <el-col :span="9">
                <el-button type="success" @click="update1(item)">修改信息</el-button>
                </el-col>
                <el-col :span="11">
                  <el-button type="success" @click="updataossFIll(item)" :disabled="item.isParsed === 'true'">解析录音</el-button>
                </el-col>
                <el-col :span="5">
                  <el-button type="success" @click="update111(item.id)">查看录音</el-button>
                </el-col>
                <el-button type="success" style="width:184px;margin-left:7px" @click="copyToClipboard(item.ossFileUrl)">复制录音地址</el-button>
                </el-row>
                </el-col>
                
              </el-row>
              </div>

              <div>
                <el-dialog
                  title="录音内容解析"
                  :visible.sync="Visible1"
                  width="30%"
                  :before-close="handleClose">
                  <span>
                    <a :href="tt">这是录音中的章节概要</a>
                  </span><br>
                  <span>
                    <a :href="ttt">这是录音解析的内容</a>
                    
                  </span>
                  <span slot="footer" class="dialog-footer">
                    <el-button @click="Visible1 = false">取 消</el-button>
                    <el-button type="primary" @click="Visible1 = false">确 定</el-button>
                  </span>
                </el-dialog>
              </div>
            </el-card>

            <div>
                <el-dialog
                    title="提示"
                    :visible.sync="deleteVisible"
                    width="30%"
                    :before-close="handleClose">
                    <span>请确认是否删除，删除后将无法恢复！</span>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="deleteVisible = false">取 消</el-button>
                        <el-button type="primary" @click="deletedd">确 定</el-button>
                    </span>
                </el-dialog>

            </div>
            <div>
                <el-dialog title="录音信息" :visible.sync="updateFormVisible">
                    <el-form :model="updateForm">
                        <el-form-item label="客户名称" :label-width="formLabelWidth">
                        <el-input v-model="updateForm.khName" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="录音标题" :label-width="formLabelWidth">
                        <el-input v-model="updateForm.lyTitle" autocomplete="off"></el-input>
                        <el-form-item label="录音描述" :label-width="formLabelWidth">
                        <el-input v-model="updateForm.description" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="客户手机号：" :label-width="formLabelWidth">
                        <el-input v-model="updateForm.khPhone" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="关键词" :label-width="formLabelWidth">
                        <el-input v-model="updateForm.guanjiance" autocomplete="off"></el-input>
                        </el-form-item>
                        </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button @click="updateFormVisible = false">取 消</el-button>
                        <el-button type="primary" @click="update11">确 定</el-button>
                    </div>
                    </el-dialog>
            </div>

            
            
            <div style="margin-top:20px">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="cx.pageInfo.pageNumber"
            :page-sizes="[5, 10, 15, 20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100]"
            :page-size="cx.pageInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>
            </div>
        </div>
    </div>
    </el-card>
    </div>
</template>
<script>
import Recorder from "js-audio-recorder";

export default {
  
    name: "audioRecorder",
    beforeCreate () {
    this.$pom('home', 'homeP', this) // 此处需要三个参数，第一个为最大盒子ref名，
//第二个为最大盒子的pc端类名，第三个直接写this，因为插件要找到vue.component实列
  },

  data() {
    //   //3.配置自定义规则
    // let validatePhone = (rule,value,callback)=>{
    //   /*console.log(rule);
    //   console.log(value);
    //   console.log(callback);*/
    //   if (!value){
    //     callback(new Error('手机号不能为空！'));
    //   }
    //   //使用正则表达式进行验证手机号码
    //   if (!/^1[3456789]\d{9}$/.test(value)){
    //     callback(new Error('手机号不正确！'));
    //   }
    // };
    return {
      // 表单校验
      rules: {
          khName: [
            { required: true, message: '请输入客户名称', trigger: 'blur' },
          ],
          luYinType: [
            { required: true, message: '请输入录音类型', trigger: 'blur' }
          ],
        },
      isUploadDisabled:false,
      dd1:'',
      Visible1 : false,
      options1:[
        {
          value1: '1',
          label1: '谈案录音'
        },
        {
          value1: '2',
          label1: '邀约录音'
        },
        {
          value1: '3',
          label1: '诉讼律师'
        },
        {
          value1: '4',
          label1: '执行'
        },
        {
          value1: '5',
          label1: '调解'
        },{
          value1: '6',
          label1: '其他'
        },
      ],
      options: [{
          value: '1',
          label: '谈案录音'
        }, {
          value: '2',
          label: '邀约录音'
        }, {
          value: '3',
          label: '诉讼律师'
        }, {
          value: '4',
          label: '执行'
        }, {
          value: '5',
          label: '调解'
        },{
          value: '6',
          label: '其他'
        }],
        // 
       previousUserName: '', // 添加一个字段来存储上一次的用户名
        receivedUserName: "",
        updateFormVisible:false,
        updateForm:{
          id: '',
          khName:'',
          lyTitle:'',
          description:'',
          khPhone:'',
          guanjiance:'',
        },
        ttt:'',
        dd:'',
        deleteVisible:false,
        toggleButtonText:"暂停录音",
        isRecording: false,
        isPaused: false,
        total:0,
        dialogVisible:false,
        cx:{
            userName: localStorage.getItem('userName'),
            khName:"",
            luYinType:"",
            pageInfo:{
              pageSize:5,
              pageNumber: 1,
            }
        },
      form:{
        khName:'',
        lyTitle:'',
        description:'',
        khPhone:'',
        guanjiance:'',
        luYinType:'',
      },
      items:[],
      audioUrl:'',
      recorder: new Recorder({
        sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
        sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
        numChannels: 1, // 声道，支持 1 或 2， 默认是1
        // compiling: false,(0.x版本中生效,1.x增加中)  // 是否边录边转换，默认是false

      }),
    };
  },
  mounted () {
   this.getUAll();   
  },
  watch: {
    isPaused(value) {
      this.toggleButtonText = value ? '恢复录音' : '暂停录音';
    },
  },
  beforeRouteEnter(to, from, next) {
    // 在渲染该组件的对应路由被确认前获取数据
        // next(vm => {
        //   vm.receivedUserName = to.query.username;
        //   vm.receivedUserid = to.query.userid;
        // });
      next();  
    },
    created() {
      // 或者在组件实例创建完成后获取数据
      this.previousUserName = this.$route.query.username || '';
      this.updateStoredData();
    },
    beforeRouteUpdate(to, from, next) {
    // 当路由参数改变时执行此方法
      this.previousUserName = this.receivedUserName; // 在路由更新前记录当前用户名
      this.updateStoredData();
      if (this.receivedUserName !== this.previousUserName) { // 检查用户名是否有变化
        localStorage.removeItem('userName');
        this.receivedUserName = '';
        this.cx.userName = '';
      }
      next();
  },
//     created() {
//   if (this.$route.query.username) {
//     localStorage.setItem('username', this.$route.query.username);
//     // 使用此值
//     this.receivedUserName = localStorage.getItem('username');
//   } else {
//     this.receivedUserName = localStorage.getItem('username') || '';
//   }
// }
  methods: {
    copyToClipboard(ossFileUrl) {
        // 创建一个隐藏的textarea元素用于复制操作
        const textarea = document.createElement('textarea');
        textarea.value = ossFileUrl; // 设置要复制的文本内容
        document.body.appendChild(textarea); // 将textarea添加到DOM中
        textarea.select(); // 选中textarea的内容
        try {
            // 执行复制操作
            document.execCommand('copy');
            this.$message.success('录音地址已复制到剪贴板'); // 可以用Element UI的提示组件显示成功信息
        } catch (err) {
            console.error('复制失败', err);
            this.$message.error('复制失败，请手动复制'); // 显示错误提示
        }
        document.body.removeChild(textarea); // 复制完成后移除textarea
    },
    downloadAutoChapters() {
      this.downloadFile(this.tt, 'auto_chapters');
    },
    
    downloadFile(url, filename) {
  this.$axios.get(url, { responseType: 'text' })
    .then(response => {
      // 创建Blob对象
      const blob = new Blob([response.data], { type: 'text/plain;charset=utf-8' });
      
      // 创建隐藏的可下载链接
      const urlObject = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = urlObject;
      link.download = `${filename}.txt`; // 设置文件名

      // 模拟点击下载
      document.body.appendChild(link);
      link.click();

      // 清理
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(urlObject);
      }, 0);
    })
    .catch(err => console.error(err));
},

      update111(id){
        var formData = new FormData()
        formData.append("id",id)
        this.$axios.post("/mp3/savee",formData).then(res => {
                        console.log(res)
                        if (res.data.data) {
                            this.tt = res.data.data.autoChaptersUrl 
                            
                            this.ttt = res.data.data.transcriptionUrl
                            this.Visible1 = true
                            // this.ta = res.data.data.
                            console.log(this.tt)
                            console.log(this.ttt)
                        }
                    }).catch(err => {
                        this.myMessage("error", err)
                    })
      },
      updataossFIll(item){
        var formData = new FormData()
        formData.append("id",item.id)
        formData.append("isParsed",'true')
        this.$axios.post("/mp3/save",formData).then(res => {
                        console.log(res)
                        if (res.data.result == "0") {
                        debugger
                        this.$message({ message: '录音解析成功请查看', type: 'warning' }); 
                        this.getUAll()
                        }
                    }).catch(err => {
                        this.myMessage("error", err)
                    })
     },
     updateStoredData() {
       const currentUserName = this.$route.query.username;
      if (currentUserName && currentUserName !== '') {
        localStorage.setItem('userName', currentUserName);
        this.receivedUserName = currentUserName;
        this.cx.userName = currentUserName;
      } else if (this.previousUserName) {
        // 如果当前路由没有username参数，但之前有设置过，保持原样
        this.receivedUserName = this.previousUserName;
        this.cx.userName = this.previousUserName;
      }
    },
    update11(){
      var formData = new FormData()
        formData.append("id",this.updateForm.id)
        formData.append("khName",this.updateForm.khName)
        formData.append("lyTitle",this.updateForm.lyTitle)
        formData.append("description",this.updateForm.description)
        formData.append("khPhone",this.updateForm.khPhone)
        formData.append("guanjiance",this.updateForm.guanjiance)
        this.$axios.post("/mp3/update",formData).then(res => {
                        console.log(res)
                        if (res.data.data) {
                        this.$message({ message: '删除成功', type: 'warning' }); 
                        this.updateFormVisible = false
                        this.getUAll()
                        }
                    }).catch(err => {
                        this.myMessage("error", err)
      })
    },
    update1(item){
           this.updateForm = {
              id: item.id,
              khName: item.khName,
              lyTitle: item.lyTitle,
              description: item.description,
              khPhone : item.khPhone,
              guanjiance : item.guanjiance
            };
          this.updateFormVisible = true
    },
    deletedd(){
        var formData = new FormData()
        formData.append("id",this.dd)
        this.$axios.post("/mp3/del",formData).then(res => {
                        console.log(res)
                        if (res.data.data) {
                        this.$message({ message: '删除成功', type: 'warning' }); 
                        this.deleteVisible = false
                        this.getUAll()
                        }
                    }).catch(err => {
                        this.myMessage("error", err)
                    })
      },
    deleted(id){
        this.dd  = id
        console.log(this.dd+"======================")
        this.deleteVisible = true
    },
    dialogVisible1(){
        this.dialogVisible = false
        // if (this.recorder.duration !== null && typeof this.recorder.duration === 'number') {
        //         this.recorder.duration = null;
        //     }
    },
    
    myMessage(type, message) { //操作后成功和失败的弹窗
            this.$message({
                message: message,
                type: type
            });
        },
   getUAll(){
        
        this.$axios.post("/mp3/queryByPage",this.cx).then(res => {
                        console.log(res)
                        if (res.data.data) {
                        
                        this.items = res.data.data.records
                        this.total = res.data.data.totalRow
                        }
                    }).catch(err => {
                        this.myMessage("error", err)
                    })
      },
    
    handleSizeChange(val) { //分页
                this.cx.pageInfo.pageSize = val
                this.getUAll()
            },
            handleCurrentChange(val) { //分页
                this.cx.pageInfo.pageNumber = val
                this.getUAll()
            },
    //开始录音
    startRecordAudio() {
      this.$refs.form.validate((valid) => {
        if (valid) {
            Recorder.getPermission().then(
                () => {
                  console.log("开始录音");
                  this.recorder.start(); // 开始录音

                this.isRecording = true;
                this.isPaused = false;
                },
                (error) => {
                  this.$message({
                    message: "请先允许该网页使用麦克风",
                    type: "info",
                  });
                  console.log(`${error.name} : ${error.message}`);
                }
              );
          } else {
            console.log('error submit!!');
            return false;
          }
      })
    },
    toggleRecordAudio() {
      if (this.isPaused) {
        if (this.recorder.resume) {
          
          this.recorder.resume();
          this.toggleButtonText = '暂停录音';
          this.isPaused = false;
        }
      } else {
        if (this.recorder.pause) {
          this.recorder.pause();
          this.toggleButtonText = '恢复录音';
          this.isPaused = true;
        }
      }
    },
  
  
    //停止录音
    stopRecordAudio() {
      if(this.form.khNme == ""){
        this.$message({ message: '没有录音', type: 'warning' }); 
        return;
      }
      console.log("停止录音");
      this.dialogVisible = true
      this.recorder.stop();
      this.isRecording = false;
      this.isPaused = false;
    },
    //播放录音
    playRecordAudio() {
      console.log("播放录音");
      this.recorder.play();
    },
    //获取PCB录音数据
    getPCBRecordAudioData() {
      var pcmBlob = this.recorder.getPCMBlob();
      console.log(pcmBlob);
    },
    //获取WAV录音数据
    getWAVRecordAudioData() {
      var wavBlob = this.recorder.getWAVBlob();
      console.log(wavBlob);
    },
    //下载PCB录音文件
    downloadPCBRecordAudioData() {
      this.recorder.downloadPCM("badao");
    },
    //下载WAV录音文件
    downloadWAVRecordAudioData() {
      this.recorder.downloadWAV("badao");
    },
    //上传wav录音数据
    uploadWAVData() {
      if(this.form.khPhone === ""){
        this.$message({ message: '填写客户手机号', type: 'warning' }); 
        return;
      }
      if(this.form.guanjiance === ""){
        this.$message({ message: '填写关键词', type: 'warning' }); 
        return;
      }
     if(this.form.lyTitle === ""){
        this.$message({ message: '填写录音标题', type: 'warning' }); 
        return;
      }
      if(this.form.description === ""){
        this.$message({ message: '填写录音概要', type: 'warning' }); 
        return;
      }
      var wavBlob = this.recorder.getWAVBlob();
       const recordingDuration = this.recorder.duration.toFixed(1);
      // 创建一个formData对象
      var formData = new FormData()
      // 此处获取到blob对象后需要设置fileName满足当前项目上传需求，其它项目可直接传把blob作为file塞入formData
      const newbolb = new Blob([wavBlob], { type: 'audio/wav' })
      //获取当时时间戳作为文件名
      const fileOfBlob = new File([newbolb], new Date().getTime() + '.wav')
      formData.append('file', fileOfBlob)
      formData.append('khName', this.form.khName)
      formData.append('lyTitle',this.form.lyTitle)
      formData.append('description',this.form.description)
      formData.append('recorder',recordingDuration)
      formData.append('userName',localStorage.getItem('userName'))
      formData.append('khPhone',this.form.khPhone)
      formData.append('guanjiance',this.form.guanjiance)
      formData.append('lyType',this.form.luYinType)
      // 调用接口上传文件
      this.$axios.post('/mp3/oss/transcribe', formData)
          .then(response => {
            // 处理成功回调，将返回的文件信息添加到fileList中显示
            // this.fileList.push({
            //   name: file.name,
            //   url: response.data.url, // 根据后端实际返回的数据结构调整
            //   status: 'success'
            // });
            this.getUAll()
            this.dialogVisible = false,
            this.form={}
            if (this.recorder.duration !== null && typeof this.recorder.duration === 'number') {
                this.recorder.duration = null;
            }

            console.log(response.data);
          })
          .catch(error => {
            console.error(error);
            
            // 处理错误回调
          });
    },
  }
}
</script>
<style lang="less">
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

body {
  display: flex;
  flex-direction: column;
}

  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .span1{
    // font-size:27px;
    color:black;
    font-weight:bold;
    margin-right:5%
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }

  
</style>